import BridgeDeckAft from 'assets/DemoCCTVs/BridgeDeckAft.mp4';
import Engines from 'assets/DemoCCTVs/Engines.mp4';
import GeneratorPort from 'assets/DemoCCTVs/GeneratorPort.mp4';
import GeneratorStbd from 'assets/DemoCCTVs/GeneratorStbd.mp4';
import MainDeckPort from 'assets/DemoCCTVs/MainDeckPort.mp4';
import MainDeckStbd from 'assets/DemoCCTVs/MainDeckStbd.mp4';

import { CameraDTO } from '../../types/security';
import { createNewItemWithCustomId, storageUtil } from '../utility';

const defaultCamera = {
  authPassword: '',
  authUsername: '',
  flipH: false,
  flipV: false,
  id: 0,
  location: 'No Location',
  mic: false,
  port: 80,
  protocol: 'MJPEG',
  ptz: false,
  relativeZoom: false,
  requiresAuth: false,
  streamPath: '.',
  streamType: 'NORMAL',
  zoom: false,
};

class DemoCamerasController {
  private _cameras: CameraDTO[] = [];

  constructor() {
    const storageVessel = storageUtil('demo-vessel-cameras', 'GET');
    if (storageVessel) {
      this._cameras = JSON.parse(storageVessel);
    } else {
      this._cameras = [
        {
          ...defaultCamera,
          ipAddress: BridgeDeckAft,
          location: 'No Location',
          name: 'Bridge Deck Aft',
          id: 0,
        },
        {
          ...defaultCamera,
          ipAddress: Engines,
          location: 'No Location',
          name: 'Engines',
          id: 1,
        },
        {
          ...defaultCamera,
          ipAddress: GeneratorPort,
          location: 'No Location',
          name: 'Generator Port',
          id: 2,
        },
        {
          ...defaultCamera,
          ipAddress: GeneratorStbd,
          location: 'No Location',
          name: 'Generator Stbd',
          id: 3,
        },
        {
          ...defaultCamera,
          ipAddress: MainDeckPort,
          location: 'No Location',
          name: 'Main Deck Port',
          id: 4,
        },
        {
          ...defaultCamera,
          ipAddress: MainDeckStbd,
          location: 'No Location',
          name: 'Main Deck Stbd',
          id: 5,
        },
      ];
    }
  }

  get cameras(): CameraDTO[] {
    return this._cameras;
  }

  addCamera = (data: CameraDTO): void => {
    this._cameras = createNewItemWithCustomId(this._cameras, data, 'id');
    storageUtil('demo-vessel-cameras', 'POST', JSON.stringify(this._cameras));
  };

  editCamera = (id: number, data: CameraDTO): void => {
    const cameraIndex = this._cameras.findIndex(camera => camera.id === id);
    if (cameraIndex === -1) {
      return;
    }
    this._cameras[cameraIndex] = data;
    storageUtil('demo-vessel-cameras', 'POST', JSON.stringify(this._cameras));
  };

  deleteCamera = (id: number): void => {
    this._cameras = this._cameras.filter(camera => camera.id !== id);
    storageUtil('demo-vessel-cameras', 'POST', JSON.stringify(this._cameras));
  };

  getCameraUrl = (id: number): string => {
    const cameraFound = this._cameras.find(camera => camera.id === id);
    if (cameraFound) {
      return cameraFound.ipAddress;
    }
    return '';
  };
}

export default DemoCamerasController;
