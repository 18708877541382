import { BasicSubscription, UserProfileDetails } from '../../store/profile/types';
import { VesselPermissionsResponse } from '../../store/selectedVessel/types';
import { UserBasicData } from '../../store/types';
import { UserType } from '../../store/vesselUserGroup';
import { demoVesselController } from '../provider';
import { createNewItemWithId, storageUtil } from '../utility';

type DataType = Partial<VesselPermissionsResponse & { user: number }>;

class DemoUserController {
  private _userFull: UserProfileDetails;
  private _userDetails: UserBasicData | null = null;
  private _users: any[] = [];
  private _userTypes: UserType[] = [];

  constructor() {
    this._userFull = require('demo/data/UserFull.json');
    this.reset();
  }

  get userTypes(): UserType[] {
    if (this._userTypes.length === 0) {
      this._userTypes = require('../data/access/UserTypes.json');
    }
    return this._userTypes;
  }

  get users(): any[] {
    if (!this._users) {
      this.reset();
    }
    return this._users;
  }

  get userFull() {
    if (!this._userFull) {
      this._userFull = require('demo/data/UserFull.json');
    }
    return this._userFull;
  }

  get userDetails() {
    if (!this._userDetails) {
      this._userDetails = {
        userId: this._userFull.userId,
        firstName: this._userFull.firstName,
        lastName: this._userFull.lastName,
        displayName: this._userFull.displayName,
        corporate: false,
        email: 'demoUser@smartyachts.link',
      };
    }
    return this._userDetails;
  }

  retrieveGeofenceUsers = (): { name: string; permission: boolean; id: string }[] => {
    const users = this.users;
    return users.map(user => {
      return { name: user.userDisplayName, permission: user.notifyGeofence, id: user.userId };
    });
  };

  updateGeofencePermission = (permissionId: string, enabled: boolean): void => {
    const userIndex = this._users.findIndex(user => user.userId === permissionId);
    if (userIndex === -1) {
      return;
    }
    this._users[userIndex] = { ...this._users[userIndex], notifyGeofence: enabled };
    storageUtil('demo-vessel-users', 'POST', JSON.stringify(this._users));
  };

  reset = (): void => {
    const storageVessel = storageUtil('demo-vessel-users', 'GET');
    if (storageVessel) {
      this._users = JSON.parse(storageVessel);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      this._users = require('../data/access/Permissions.json');
    }
  };

  addUser = (email: string): void => {
    const newUser = {
      userId: 1,
      userEmail: email,
      userState: 'INVITED',
      userDisplayName: email,
      userMobileNumber: '',
      userTypeId: 5,
      userTypeName: 'Vessel Crew',
      userManager: 'DENY',
      deviceManager: 'DENY',
      routineManager: 'DENY',
      alertManager: 'DENY',
      auditLogs: 'DENY',
      documents: 'DENY',
      securityManager: 'DENY',
      billingManager: 'DENY',
      allowDeviceControl: false,
      allowEditVessel: false,
      receiveNotifications: false,
      receiveRequests: false,
      notifyGeofence: false,
      allowLocationServices: false,
      allowGrantTechnicalAccess: false,
      addedToVessel: new Date().toLocaleDateString(),
      addedByUserName: 'Demo User',
      corporate: false,
      paused: false,
      pending: true,
      appSettingsId: 'guest',
      appSettingsName: 'Default Vessel Settings',
    };

    if (this._users.length > 0) {
      this._users = createNewItemWithId(this._users, newUser, 'userId');
    }
    storageUtil('demo-vessel-users', 'POST', JSON.stringify(this._users));
  };

  pause = (id: string | number, state: boolean): void => {
    const newUsers = [...this._users];
    newUsers.forEach((user, index) => {
      if (user.userId === id) {
        newUsers[index] = { ...user, paused: state };
      }
    });
    this._users = newUsers;
    storageUtil('demo-vessel-users', 'POST', JSON.stringify(this._users));
  };

  updatePermissions = (data: DataType): void => {
    if (this._users) {
      let foundUser = this._users.find(user => user.userId === data.user);
      if (!foundUser) {
        return;
      }
      const keys = Object.keys(data);
      keys.forEach(key => {
        let newKey = key;
        if (key === 'userType') {
          newKey = 'userTypeName';
        }
        if (newKey in foundUser) {
          if (newKey === 'userTypeName') {
            const userType = this._userTypes.find(
              userType => userType.id === Number(data[key as keyof DataType])
            );
            if (userType) {
              foundUser = { ...foundUser, userTypeName: userType.name };
            }
          } else {
            foundUser = { ...foundUser, [newKey]: data[key as keyof DataType] };
          }
        }
      });
      const index = this._users.findIndex(user => user.userId === data.user);
      if (index === -1) {
        return;
      }
      const newUsers = [...this._users];
      newUsers[index] = foundUser;
      this._users = newUsers;
      storageUtil('demo-vessel-users', 'POST', JSON.stringify(this._users));
    }
  };

  delete = (id: string | number): void => {
    this._users = this._users.filter(user => user.userId !== id);
    storageUtil('demo-vessel-users', 'POST', JSON.stringify(this._users));
  };

  retrieveSubscriptions = (): (BasicSubscription | null | undefined)[] => {
    const subscription = demoVesselController.vessel?.vesselSubscription;
    return [subscription];
  };

  retrieveBilling = () => {
    return [];
  };
}

export default DemoUserController;
