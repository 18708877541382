import { ValueHolder } from '../../../../store/selectedDevice/types';
import { InitialValueHolderI } from '../../../dataImporter';
import { generateValueHolders } from './devicesUtils';

class GudeController {
  generateProperties = (): Partial<ValueHolder>[] => {
    const devices: {
      name: string;
      bus: string;
      valueHolders: InitialValueHolderI[];
      // eslint-disable-next-line @typescript-eslint/no-var-requires
    }[] = require('demo/data/devices/AdvancedDevices.json');
    const gudeDevice = devices.find(
      device => device.name === 'Gude 12-Fold Switched and Metered PDU'
    );
    if (!gudeDevice) {
      return [];
    }
    const [valueholders] = generateValueHolders(0, 0, gudeDevice.valueHolders, 'GUDE', 'GUDE');
    return valueholders;
  };
}

export default GudeController;
