import { VesselDevice } from 'store/selectedDevice/types';

import { InitialValueHolderI } from '../../../dataImporter';
import { buses, generateValueHolders } from './devicesUtils';

export class DemoDeviceGenerator {
  private _name: string;
  private _bus: string;
  private _device: Partial<VesselDevice> | null = null;
  private _valueHolders: InitialValueHolderI[] = [];
  constructor(
    name: string,
    bus: string,
    valueholders: InitialValueHolderI[],
    deviceId: number,
    valueHolderId: number
  ) {
    this._name = name;
    this._bus = bus;
    this._valueHolders = valueholders;
    this.generateDevice(deviceId, valueHolderId);
  }

  generateDevice = (deviceId: number, valueHolderId: number) => {
    const [valueholders, state] = generateValueHolders(
      valueHolderId,
      deviceId,
      this._valueHolders,
      this._name,
      this._bus
    );

    const busFound = buses.find(bus => bus.bus === this._bus);

    this._device = {
      id: deviceId.toString(),
      name: this._name,
      friendlyName: this._name,
      manufacturer: 'CAT',
      model: '',
      modelVersion: '',
      deviceSerial: '',
      gatewayAddress: '',
      presentAddress: '0',
      softwareVersion: '',
      state: 'NORMAL',
      bus: {
        bus: busFound ? busFound.bus : this._bus,
        source: busFound ? busFound.source : this._bus,
        supportsPropertyAdd: busFound ? busFound.supportsPropertyAdd : false,
      },
      userDefinedName: 'Engine Port',
      // @ts-ignore
      valueHolders: valueholders,
      deviceState: state,
      deviceNotifications: false,
      onlineState: true,
      controllable: false,
      cloudSync: true,
      systemDevice: false,
    };
  };

  get device(): VesselDevice {
    return this._device as VesselDevice;
  }
}
