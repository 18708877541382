import { store } from 'store';
import { NewSimpleDevice } from 'store/simpleDevice/types';
import { SimpleDeviceType } from 'types/simpleDevices';

import { mapProperty } from '../demo/controllers/devices/simpleDevices/simpleDevicesUtils';
import {
  demoAlertController,
  demoCamerasController,
  demoDevicesController,
  demoSimpleDevicesController,
} from '../demo/provider';
import { VesselSimpleDevicesSettings } from '../types/permissions';
import { MyResponse, RequestConfig, requestMiddleware } from './request';
import { AlertPaginatedType } from './selectedVessel';

export async function postSetWarningLevels(
  deviceId: string,
  payload: {
    propertyId: number;
    warnLevelLow: string;
    warnLevelHigh: string;
    criticalLevelLow: string;
    criticalLevelHigh: string;
    warnTextLow: string;
    warnTextHigh: string;
    criticalTextLow: string;
    criticalTextHigh: string;
    repeatOptions: string;
    instant: boolean;
    alertDelay: number;
    instantAlertLevel?: string;
  }
): Promise<MyResponse> {
  demoDevicesController.setWarningLevels(deviceId, payload);
  return requestMiddleware({});
}

export async function getSimpleDevicesByType(authCode: string, type: string): Promise<MyResponse> {
  return requestMiddleware(demoSimpleDevicesController.retrieveDevice(type));
}

export async function getAllSimpleDevicesFlat(id: string): Promise<MyResponse> {
  return requestMiddleware(demoSimpleDevicesController.retrieveAllDevicesFlat());
}

export async function getAllSimpleDeviceTemplates(): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(require('demo/data/simpleDevices/templates.json'));
}

export async function postAddSimpleDevice(device: NewSimpleDevice): Promise<MyResponse> {
  demoSimpleDevicesController.createSimpleDevice(device);
  return requestMiddleware({});
}

export async function postMapProperty(
  fieldId: number,
  propertyId: number,
  vesselId: string
): Promise<MyResponse> {
  mapProperty(fieldId, propertyId);
  return requestMiddleware({});
}

export async function UnmapProperty(vesselId: string, fieldId: number): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/unmap/${fieldId}`,
    method: 'POST',
  });
}

export async function postSendSimpleCommand(
  deviceProperty: number,
  value: number | string,
  authCode: string
): Promise<MyResponse> {
  demoSimpleDevicesController.sendCommand(deviceProperty, value);
  return requestMiddleware({});
}

export async function deleteSimpleDevice(vesselId: string, deviceId: number): Promise<MyResponse> {
  demoSimpleDevicesController.deleteSimpleDevice(deviceId);
  return requestMiddleware({});
}

export async function postEditSimpleDeviceDetails(
  vesselId: string,
  id: number,
  name: string,
  manufacturer: string,
  model: string,
  serial: string,
  location: string
): Promise<MyResponse> {
  demoSimpleDevicesController.updateSimpleDeviceData(
    id,
    name,
    manufacturer,
    model,
    serial,
    location
  );
  return requestMiddleware({});
}

export async function getGraphData(
  propertyId: number,
  dateFrom: string,
  dateTo: string,
  authCode: string
): Promise<MyResponse> {
  return requestMiddleware(demoDevicesController.retrieveGraphData(propertyId, dateFrom, dateTo));
}

export async function postSetLocationSwitch(
  location: string,
  state: string,
  authCode: string
): Promise<MyResponse> {
  demoSimpleDevicesController.updateLocation(location, state);
  return requestMiddleware({});
}

export async function postSetLocationShade(
  location: string,
  state: string,
  authCode: string
): Promise<MyResponse> {
  demoSimpleDevicesController.updateLocation(location, state);
  return requestMiddleware({});
}

export async function postSetMetadata(
  vesselId: string,
  id: number,
  key: string,
  value: string
): Promise<MyResponse> {
  demoSimpleDevicesController.setMetadata(id, key, value);
  return requestMiddleware({});
}

export function getCameraUrl(
  deviceType: SimpleDeviceType,
  authCode: string,
  id: number,
  type?: string
): string {
  const url = demoCamerasController.getCameraUrl(id);
  return url.substr(0, 7) === '/static' ? url : url.includes('https://') ? url : `http://${url}`;
}
//Does not return anything until weather is added or changed to work like mfd/touchscreen.
export async function getAlerts(authCode: string): Promise<MyResponse | null> {
  return requestMiddleware(demoAlertController.alerts);
}

export async function postSilenceAllAlerts(authCode: string): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/silenceAll`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSilenceAlerts(
  authCode: string,
  alertId: string
): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${
      store.getState().selectedVessel.selectedVessel?.id
    }/alerts/${alertId}/silence`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postAckVesselAlerts(
  authCode: string,
  alertId: string
): Promise<MyResponse | null> {
  const config: RequestConfig = {
    url: `/api/vessel/${store.getState().selectedVessel.selectedVessel?.id}/alerts/${alertId}/ack`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getAlertLogData(
  authCode: string,
  data: AlertPaginatedType
): Promise<MyResponse | null> {
  const logs = demoAlertController.retrievePage(data);
  return requestMiddleware(logs);
}

export async function getSettingsData(authCode?: string): Promise<MyResponse | null> {
  return requestMiddleware(demoSimpleDevicesController.settings);
}

export async function setSettingsData(
  authCode: string,
  data: Partial<VesselSimpleDevicesSettings>
): Promise<MyResponse | null> {
  return null;
}

export async function getWeatherData(authCode: string): Promise<MyResponse | null> {
  return null;
}

export async function getLocations(): Promise<MyResponse | null> {
  return requestMiddleware(demoSimpleDevicesController.locations);
}

export function getBackgroundImgUrl(authCode: string, id?: string): string {
  return '';
}

export async function getGaugeDashboard(authCode: string, type: string): Promise<MyResponse> {
  demoSimpleDevicesController.retrieveGauges(type);
  // demoSimpleDevicesController.randomUpdate('Gauges');
  return requestMiddleware(demoSimpleDevicesController.gauges);
}

export async function postFieldOrder(
  vesselId: string,
  deviceId: number,
  order: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/simpleDevices/setFieldOrder?field=${deviceId}&fieldOrder=${order}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postToggleGauge(
  vesselId: string,
  deviceId: number,
  include: boolean
): Promise<MyResponse> {
  demoSimpleDevicesController.setIncludeInGauges(deviceId, include);
  return requestMiddleware({});
}
