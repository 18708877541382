import {
  defaultAlertConfig,
  generateAdvancedDevices,
  generateSimpleDevicesFromData,
} from 'demo/dataImporter';
import { v4 as uuidv4 } from 'uuid';

import {
  DeviceProperty,
  Property,
  ValueHolder,
  VesselDevice,
} from '../../../../store/selectedDevice/types';
import { Bus } from '../../../../store/selectedVessel/types';
import { DeviceBasicResponse, DeviceRequest } from '../../../../types/devices';
import { DateFilters, ValueResponse } from '../../../../types/graphs';
import { Group, GroupItem } from '../../../../types/groups';
import { AlertLog } from '../../../../types/logs';
import { SimpleDeviceFieldWrapper } from '../../../../types/simpleDevices';
import { CalibrationConfig } from '../../../../views/CalibrationsView';
import { getDemoGraphData } from '../../../data/graph';
import {
  demoAlertController,
  demoNotificationsController,
  demoVesselController,
} from '../../../provider';
import {
  clearDemoData,
  createNewItemWithCustomId,
  getParsedValue,
  getRandom,
  getRandomInt,
  storageUtil,
} from '../../../utility';
import { PropertyAlertConfig } from '../../alertController';
import { buses } from './devicesUtils';
import GudeController from './gudeController';

const defaultValueHolder = {
  feedbackInstance: '1/1/1',
  instance: '0',
  active: true,
  multiplier: 1,
  offset: 0.0,
  controllable: true,
  invert: false,
  includeInSeaReady: true,
  rangeLow: 0,
  rangeHigh: 0,
  curveName: '',
  warnTextHigh: null,
  warnTextLow: null,
  criticalTextHigh: null,
  criticalTextLow: null,
};

const defaultDevice = {
  modelVersion: '1',
  presentAddress: 'local',
  version: '1',
  onlineState: true,
};

type DemoGroup = Group & { ids: { device: string; property: number }[] };

class DemoDevicesController {
  private _devices: VesselDevice[] = [];
  private _device: VesselDevice | null = null;
  private _groups: DemoGroup[] = [];
  private _buses: Bus[] = [];
  private _busProps: DeviceProperty[] = [];
  private _calibrations: CalibrationConfig[] = [];
  public deviceId: string | null = null;

  constructor() {
    this.reset();
  }

  get devices(): VesselDevice[] {
    return this._devices;
  }

  get device(): VesselDevice {
    if (this.deviceId) {
      this._device = this.retrieveDeviceInfo(this.deviceId);
    }
    return this._device as VesselDevice;
  }

  get groups(): DemoGroup[] {
    return this._groups.map(group => {
      return { ...group, groupSize: group.ids.length };
    });
  }

  get buses(): Bus[] {
    if (this._buses.length === 0) {
      this._buses = buses;
    }
    return this._buses;
  }

  get calibrations(): CalibrationConfig[] {
    return this._calibrations;
  }

  retrieveBasic = (): DeviceBasicResponse[] => {
    const devices = this.devices;
    const newDevices: DeviceBasicResponse[] = [];
    devices.forEach(device => {
      let summaryText = '';
      const valueHoldersNames: string[] = [];
      device.valueHolders.forEach(valueHolder => {
        if (valueHolder.includeInSummary) {
          const parseValue = getParsedValue(valueHolder);
          const newSummaryText = `${valueHolder.name}: ${parseValue}\n`;
          summaryText = summaryText + newSummaryText;
        }
        valueHoldersNames.push(valueHolder.name);
      });
      const newDevice: DeviceBasicResponse = {
        id: device.id,
        name: device.name,
        bus: device.bus,
        summaryText: summaryText,
        gatewayAddress: device.gatewayAddress,
        controllable: device.controllable,
        cloudSync: device.cloudSync,
        deviceState: device.state,
        valueHolders: valueHoldersNames,
        systemDevice: device.systemDevice,
        deviceNotifications: device.deviceNotifications,
      };
      newDevices.push(newDevice);
    });
    return newDevices;
  };

  retrieveDeviceInfo = (id: string): VesselDevice | null => {
    this.randomDeviceUpdate(id);
    const date = new Date().toString();
    const deviceFound = this._devices.find(device => device.id === id);
    if (deviceFound) {
      return this.retrieveDevice(deviceFound, date);
    }
    return null;
  };

  retrieveDevice = (device: VesselDevice, date: string): VesselDevice => {
    const newValueHolders: ValueHolder[] = [];
    let summaryText = '';
    device.valueHolders.forEach(valueHolder => {
      if (valueHolder.includeInSummary) {
        const parseValue = getParsedValue(valueHolder);
        const newSummaryText = `${valueHolder.name}: ${parseValue}\n`;
        summaryText = summaryText + newSummaryText;
      }
      const newValueHolder = this.mergeValueHolder(valueHolder);
      newValueHolders.push(newValueHolder);
    });
    let newDevice = this.mergeDevice(device, date, summaryText);
    newDevice = { ...newDevice, valueHolders: newValueHolders };
    return newDevice;
  };

  retrieveDevicesFull = (): VesselDevice[] => {
    const date = new Date().toString();
    const devices = this.devices;
    return devices.map(device => {
      return this.retrieveDevice(device, date);
    });
  };

  mergeValueHolder = (valueHolder: Partial<ValueHolder>): ValueHolder => {
    const parseValue = getParsedValue(valueHolder);
    let alertConfig: Partial<PropertyAlertConfig> | undefined =
      demoAlertController.propertyAlertConfigs.find(
        propertyAlertConfig => propertyAlertConfig.propertyId === valueHolder.id
      );
    if (!alertConfig) {
      alertConfig = defaultAlertConfig;
    }
    return {
      ...defaultValueHolder,
      ...valueHolder,
      parsedValue: parseValue,
      ...alertConfig,
    } as ValueHolder;
  };

  mergeDevice = (device: Partial<VesselDevice>, date: string, summary: string): VesselDevice => {
    return {
      ...defaultDevice,
      ...device,
      lastStatusDate: date,
      summaryText: summary,
      lastSeen: date,
      deviceState: device.state,
    } as VesselDevice;
  };

  mergeDeviceRequestValueHolder = (
    valueHolder: Partial<ValueHolder>,
    busProps: DeviceProperty[]
  ): ValueHolder => {
    let busPropFound: DeviceProperty | undefined = undefined;
    if (busProps.length > 0) {
      busPropFound = busProps.find(busProp => busProp.uid === valueHolder.propertyUid);
    }
    return {
      id: +new Date(),
      ...defaultValueHolder,
      ...valueHolder,
      ...(busPropFound && {
        propertyName: busPropFound.name,
        category: busPropFound.category,
        dictionary: busPropFound.dictionary,
        rangeHigh: valueHolder.rangeHigh ? valueHolder.rangeHigh : busPropFound.maxVal,
        rangeLow: valueHolder.rangeHigh ? valueHolder.rangeLow : busPropFound.minVal,
        unit: busPropFound.unit,
      }),
      alertDelayMs: 0,
      value: '0',
      state: 'NORMAL',
    } as ValueHolder;
  };

  addDevice = (newMonitoredDevice: DeviceRequest): void => {
    const busFound = this.buses.find(bus => bus.bus === newMonitoredDevice.bus);
    let newDevice: Partial<VesselDevice> = {
      bus: busFound ?? this.buses[0],
      cloudSync: true,
      controllable: true,
      deviceNotifications: true,
      deviceSerial: newMonitoredDevice.deviceSerial,
      deviceState: '',
      friendlyName: newMonitoredDevice.name,
      gatewayAddress: '',
      id: '0',
      manufacturer: '',
      model: '',
      modelVersion: '',
      onlineState: true,
      prefix: '',
      presentAddress: '',
      softwareVersion: '',
      state: 'NORMAL',
      systemDevice: false,
      uid: uuidv4(),
      userDefinedName: newMonitoredDevice.name,
      valueHolders: newMonitoredDevice.deviceProperties.map(deviceProp => {
        return this.mergeDeviceRequestValueHolder(deviceProp, this._busProps);
      }),
      name: newMonitoredDevice.name,
    };
    if (newMonitoredDevice.bus === 'GUDE') {
      const gudeController = new GudeController();
      const properties = gudeController.generateProperties();
      newDevice = { ...newDevice, valueHolders: properties as ValueHolder[] };
    }
    this._devices = createNewItemWithCustomId(
      this._devices,
      newDevice,
      'id',
      true
    ) as VesselDevice[];
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._busProps = [];
  };

  deleteDevice = (id: string): void => {
    this._devices = this._devices.filter(device => device.id !== id);
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
  };

  updateDevice = (deviceId: string, data: Partial<VesselDevice>): void => {
    const deviceIndexFound = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndexFound === -1) {
      return;
    }
    const keys = Object.keys(data);
    keys.forEach(key => {
      if (!data[key as keyof VesselDevice] && !(key in this._devices)) {
        return;
      }
      this._devices[deviceIndexFound] = {
        ...this._devices[deviceIndexFound],
        [key]: data[key as keyof VesselDevice],
      };
    });
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._device = null;
  };

  retrieveAlertLogs = (): AlertLog[] => {
    const newAlertLogs: AlertLog[] = [];
    if (!this._device) {
      return newAlertLogs;
    }
    demoAlertController.time = 23;
    // @ts-ignore
    demoAlertController.logs.forEach((log: AlertLog & { alertId: string }) => {
      const id = log.alertId.substr(log.alertId.indexOf('_') + 1);
      if (this._device && !isNaN(Number(id))) {
        this._device.valueHolders.forEach(valueHolder => {
          if (valueHolder.id === Number(id)) {
            newAlertLogs.push(log);
          }
        });
      }
    });
    return newAlertLogs;
  };

  updateDeviceNotifications = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    this._devices[deviceIndex].deviceNotifications = state;
    this._device = null;
  };

  updateDeviceControllable = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    this._devices[deviceIndex].controllable = state;
    this._device = null;
  };

  updateDeviceSync = (deviceId: string, state: boolean): void => {
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    this._devices[deviceIndex].cloudSync = state;
    this._device = null;
  };

  updateVhValue = (devicePropertyId: number, state: boolean, key: keyof ValueHolder): void => {
    let deviceIndexFound: number | null = null;
    let vhIndexFound = -1;
    this._devices.forEach((device, deviceIndex) => {
      if (deviceIndexFound) {
        return;
      }
      vhIndexFound = device.valueHolders.findIndex(vh => vh.id === devicePropertyId);
      if (vhIndexFound !== -1) {
        deviceIndexFound = deviceIndex;
        return;
      }
    });
    if (!deviceIndexFound) {
      return;
    }
    // @ts-ignore
    this._devices[deviceIndexFound].valueHolders[vhIndexFound][key] = state;
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._device = null;
  };

  updateDeviceProperty = (deviceId: string, propertyId: number, data: Property): void => {
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    const propertyIndex = this._devices[deviceIndex].valueHolders.findIndex(
      property => property.id === propertyId
    );
    if (propertyIndex === -1) {
      return;
    }
    this._devices[deviceIndex].valueHolders[propertyIndex] = {
      ...this._devices[deviceIndex].valueHolders[propertyIndex],
      ...(data as Partial<ValueHolder>),
    };
    this._device = null;
  };

  addDeviceProperty = (deviceId: string, data: Property): void => {
    if (this._devices.length > 0) {
      const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
      if (deviceIndex === -1) {
        return;
      }
      const valueHolder = this.mergeDeviceRequestValueHolder(
        data as Partial<ValueHolder>,
        this.retrieveBusProps(this._devices[deviceIndex].bus.bus)
      );
      this._devices[deviceIndex] = {
        ...this._devices[deviceIndex],
        valueHolders: [...this._devices[deviceIndex].valueHolders, valueHolder],
      };
      storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
      this._device = null;
      this._busProps = [];
    }
  };

  updateDevicePropertyValue = (deviceProperty: number, value: number | string): void => {
    if (this._devices.length <= 0) {
      return;
    }
    for (let i = 0; i < this._devices.length; i++) {
      for (
        let valueHolderIndex = 0;
        valueHolderIndex < this._devices[i].valueHolders.length;
        valueHolderIndex++
      ) {
        if (this._devices[i].valueHolders[valueHolderIndex].id === deviceProperty) {
          const deviceState = this.updateAlert(
            this._devices[i].valueHolders[valueHolderIndex],
            i,
            this._devices[i].id,
            value.toString()
          );
          if (deviceState) {
            this._devices[i].state = deviceState;
            demoAlertController.updateAlertConfig({ state: deviceState }, this._devices[i].id);
          }
          this._devices[i].valueHolders[valueHolderIndex].value = value.toString();
          break;
        }
      }
    }
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._device = null;
  };

  setWarningLevels = (
    deviceId: string,
    payload: {
      propertyId: number;
      warnLevelLow: string;
      warnLevelHigh: string;
      criticalLevelLow: string;
      criticalLevelHigh: string;
      warnTextLow: string;
      warnTextHigh: string;
      criticalTextLow: string;
      criticalTextHigh: string;
      repeatOptions: string;
      instant: boolean;
      alertDelay: number;
      instantAlertLevel?: string;
    }
  ): void => {
    const deviceIndexFound = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndexFound === -1) {
      return;
    }
    const vhIndexFound = this._devices[deviceIndexFound].valueHolders.findIndex(
      vh => vh.id === payload.propertyId
    );
    if (vhIndexFound === -1) {
      return;
    }
    demoAlertController.updateAlertConfig(
      { ...payload, propertyId: this._devices[deviceIndexFound].valueHolders[vhIndexFound].id },
      this._devices[deviceIndexFound].id
    );

    if (
      payload.warnLevelLow ||
      payload.warnLevelHigh ||
      payload.criticalLevelLow ||
      payload.criticalLevelHigh
    ) {
      this._devices[deviceIndexFound].valueHolders[vhIndexFound] = {
        ...this._devices[deviceIndexFound].valueHolders[vhIndexFound],
        hasAlertConfig: true,
      };
    } else {
      this._devices[deviceIndexFound].valueHolders[vhIndexFound] = {
        ...this._devices[deviceIndexFound].valueHolders[vhIndexFound],
        hasAlertConfig: false,
      };
    }
    const deviceState = this.updateAlert(
      this._devices[deviceIndexFound].valueHolders[vhIndexFound],
      deviceIndexFound,
      deviceId,
      this._devices[deviceIndexFound].valueHolders[vhIndexFound].value
    );
    if (deviceState) {
      this._devices[deviceIndexFound].state = deviceState;
      demoAlertController.updateAlertConfig(
        {
          state: deviceState,
          propertyId: this._devices[deviceIndexFound].valueHolders[vhIndexFound].id,
        },
        this._devices[deviceIndexFound].id
      );
    }
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._device = null;
  };

  updateAlert = (
    vh: ValueHolder,
    deviceIndex: number,
    deviceId: string,
    newValue: string
  ): string | null => {
    if (!vh.hasAlertConfig) {
      return vh.state;
    }
    const alertConfig = demoAlertController.propertyAlertConfigs.find(
      propAlertConfig => propAlertConfig.propertyId === vh.id
    );
    if (!alertConfig) {
      return vh.state;
    }
    const [newAlertConfig, type, state] = this.checkAlert(alertConfig, vh.value, newValue);

    let newDeviceState = null;
    if (type === 'new' || type === 'update') {
      const deviceState = alertConfig.state;
      if ((deviceState === 'NORMAL' || deviceState === 'WARNING') && state === 'CRITICAL') {
        newDeviceState = 'CRITICAL';
      } else if (deviceState === 'NORMAL' && state === 'WARNING') {
        newDeviceState = 'WARNING';
      } else {
        newDeviceState = 'NORMAL';
      }
      demoNotificationsController.createNotification(
        `Device ${vh.name}: ${newAlertConfig.state} Value Reached. Current Value: ${vh.value}${vh.unit}`
      );
      demoAlertController.createNewAlert(
        'PROPERTY',
        `prop_${vh.id}`,
        `${vh.name}`,
        newAlertConfig.state === 'CRITICAL'
          ? 'Device in CRITICAL alert state'
          : newAlertConfig.state === 'WARNING'
          ? 'Device in WARNING alert state'
          : '',
        vh.value,
        newAlertConfig.state,
        vh,
        deviceId
      );
    } else if (type === 'remove') {
      demoAlertController.removeAlert(`prop_${vh.id}`);
    }
    return newDeviceState;
  };

  checkVesselDevices = (): void => {
    const devicesMonitored = this._devices.length;
    let warningCount = 0;
    let criticalCount = 0;
    const newDevices: VesselDevice[] = [];
    this._devices.forEach(device => {
      if (device.state === 'WARNING') {
        warningCount++;
      }
      if (device.state === 'CRITICAL') {
        criticalCount++;
      }
      let alertLevel = 'NORMAL';
      device.valueHolders.forEach(vh => {
        if (alertLevel === 'CRITICAL') {
          return;
        }
        const vhState = vh.state;
        if (vhState === 'WARNING' && alertLevel === 'NORMAL') {
          alertLevel = 'WARNING';
        }
        if (vhState === 'CRITICAL' && (alertLevel === 'NORMAL' || alertLevel === 'WARNING')) {
          alertLevel = 'CRITICAL';
        }
      });
      newDevices.push({ ...device, state: alertLevel });
    });
    demoVesselController.updateVessel({
      numberOfMonitoredDevices: devicesMonitored,
      devicesOnline: devicesMonitored,
      criticalCount,
      warningCount,
    });
    if (this._devices.length === newDevices.length) {
      this._devices = newDevices;
      storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    }
  };

  checkAlert = (
    alertConfig: PropertyAlertConfig,
    currentValue: string,
    newValue: string
  ): [
    PropertyAlertConfig,
    'update' | 'new' | 'same' | 'remove',
    'NORMAL' | 'WARNING' | 'CRITICAL'
  ] => {
    if (alertConfig.instant) {
      if (currentValue !== newValue) {
        const prevState = alertConfig.state;
        return [
          { ...alertConfig, state: alertConfig.instantAlertLevel },
          prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update',
          alertConfig.instantAlertLevel as 'NORMAL' | 'WARNING' | 'CRITICAL',
        ];
      } else {
        return [alertConfig, 'same', alertConfig.state as 'NORMAL' | 'WARNING' | 'CRITICAL'];
      }
    }
    const prevState = alertConfig.state;
    const value = !isNaN(Number(currentValue)) ? Number(currentValue) : 0;
    const warnLevelLow = !isNaN(Number(alertConfig.warnLevelLow))
      ? Number(alertConfig.warnLevelLow)
      : 'NOT_SET';
    const warnLevelHigh = !isNaN(Number(alertConfig.warnLevelHigh))
      ? Number(alertConfig.warnLevelHigh)
      : 'NOT_SET';
    const criticalLevelLow = !isNaN(Number(alertConfig.criticalLevelLow))
      ? Number(alertConfig.criticalLevelLow)
      : 'NOT_SET';
    const criticalLevelHigh = !isNaN(Number(alertConfig.criticalLevelHigh))
      ? Number(alertConfig.criticalLevelHigh)
      : 'NOT_SET';
    if (criticalLevelHigh !== 'NOT_SET' && value >= criticalLevelHigh) {
      return [
        { ...alertConfig, state: 'CRITICAL' },
        prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update',
        'CRITICAL',
      ];
    } else if (warnLevelHigh !== 'NOT_SET' && value >= warnLevelHigh) {
      return [
        { ...alertConfig, state: 'WARNING' },
        prevState === 'WARNING' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update',
        'WARNING',
      ];
    } else if (criticalLevelLow !== 'NOT_SET' && value <= criticalLevelLow) {
      return [
        { ...alertConfig, state: 'CRITICAL' },
        prevState === 'CRITICAL' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update',
        'CRITICAL',
      ];
    } else if (warnLevelLow !== 'NOT_SET' && value <= warnLevelLow) {
      return [
        { ...alertConfig, state: 'WARNING' },
        prevState === 'WARNING' ? 'same' : prevState === 'NORMAL' ? 'new' : 'update',
        'WARNING',
      ];
    } else {
      return [
        { ...alertConfig, state: 'NORMAL' },
        prevState === 'NORMAL' ? 'same' : 'remove',
        'NORMAL',
      ];
    }
  };

  deleteDeviceProperty = (deviceId: string, propertyId: number): void => {
    if (this._devices.length <= 0) {
      return;
    }
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    this._devices[deviceIndex] = {
      ...this._devices[deviceIndex],
      valueHolders: [
        ...this._devices[deviceIndex].valueHolders.filter(
          valueHolder => valueHolder.id !== propertyId
        ),
      ],
    };
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
    this._device = null;
  };

  retrieveGroupByName = (name: string): GroupItem[] => {
    const groupSelected = this._groups.find(group => group.groupName === name);
    const response: GroupItem[] = [];
    if (!groupSelected) {
      return response;
    }
    groupSelected.ids.forEach(idObj => {
      const deviceFound = this._devices.find(device => device.id === idObj.device);
      if (!deviceFound) {
        return;
      }
      const propertyFound = deviceFound.valueHolders.find(
        property => property.id === idObj.property
      );
      if (!propertyFound) {
        return;
      }
      const newProperty = this.mergeValueHolder(propertyFound);
      response.push({
        property: newProperty,
        deviceId: deviceFound.id,
        deviceControllable: deviceFound.controllable,
        deviceBus: deviceFound.bus,
        deviceName: deviceFound.name,
        deviceOnline: deviceFound.onlineState,
      });
    });
    return response;
  };

  updateGroupName = (groupName: string, newGroupName: string): void => {
    const groupIndex = this._groups.findIndex(group => group.groupName === groupName);
    if (groupIndex === -1) {
      return;
    }
    this._groups[groupIndex].groupName = newGroupName;
  };

  retrieveBusProps = (id: string): DeviceProperty[] => {
    switch (id) {
      case 'MODBUSTCP':
        this._busProps =
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('../../../data/devices/properties/ModbusTCPProp.json') as DeviceProperty[];
        return this._busProps;
      case 'MODBUSRTU':
        this._busProps =
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('../../../data/devices/properties/ModbusRTUProp.json') as DeviceProperty[];
        return this._busProps;
      case 'KNX':
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        this._busProps = require('../../../data/devices/properties/knx.json') as DeviceProperty[];
        return this._busProps;
      default:
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        this._busProps = require('../../../data/devices/properties/knx.json') as DeviceProperty[];
        return this._busProps;
    }
  };

  reset = (): void => {
    // clearDemoData();
    const storageVessel = storageUtil('demo-vessel-advanced-devices', 'GET');
    if (storageVessel) {
      this._devices = JSON.parse(storageVessel);
    } else if (this._devices.length === 0) {
      this._devices = generateAdvancedDevices();
    }
    generateSimpleDevicesFromData(this._devices);
    const groupDeviceId = this._devices[0].id;
    const groupPropIds = [
      this._devices[0].valueHolders[0].id,
      this._devices[0].valueHolders[1].id,
      this._devices[0].valueHolders[2].id,
    ];
    if (this._groups.length === 0) {
      this._groups = [
        {
          ids: [
            { device: groupDeviceId, property: groupPropIds[0] },
            { device: groupDeviceId, property: groupPropIds[1] },
            { device: groupDeviceId, property: groupPropIds[2] },
          ],
          groupName: 'Batteries',
          groupSize: 3,
          groupState: 'NORMAL',
        },
      ];
    }
    const storageVesselCalibrations = storageUtil('demo-vessel-calibrations', 'GET');
    if (storageVesselCalibrations) {
      this._calibrations = JSON.parse(storageVesselCalibrations);
    } else {
      this._calibrations = [];
    }
    this.checkVesselDevices();
  };

  addNewCalibration = (data: CalibrationConfig): void => {
    this._calibrations = createNewItemWithCustomId(this._calibrations, data, 'id');
    storageUtil('demo-vessel-calibrations', 'POST', JSON.stringify(this._calibrations));
  };

  retrieveGraphData = (propertyId: number, dateFrom: string, dateTo: string): ValueResponse[] => {
    const demoGraphController = new DemoGraphController();
    let vhFound: ValueHolder | undefined = undefined;
    this._devices.forEach(device => {
      if (vhFound) {
        return;
      }
      vhFound = device.valueHolders.find(vh => vh.id === propertyId);
    });
    if (!vhFound) {
      return [];
    }
    return demoGraphController.generateData(dateFrom, dateTo, vhFound);
  };

  randomDeviceUpdate = (deviceId: string): void => {
    /**
     * Randomly change the value of the fields by random values within 0->100 or min->max
     * to simulate live data.
     */
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    const minInterval = 0.1;
    const maxInterval = 0.9;
    let newDevice: VesselDevice = this._devices[deviceIndex];
    const newValueHolders: ValueHolder[] = [];
    newDevice.valueHolders.forEach(vh => {
      let newVh = vh;
      const regex = /.*\..*\..*/;
      const containsDict = vh.dictionary && Object.keys(vh.dictionary).length > 0;
      if (containsDict || isNaN(Number(vh.value)) || regex.test(vh.value) || vh.controllable) {
        newValueHolders.push(newVh);
        return;
      }
      const currentVal = Number(vh.value);
      const randomSymbol = getRandomInt(1, 2);

      const randomNumber = getRandom(minInterval, maxInterval, 2);
      const max = vh.gaugeHigh !== 0 ? vh.gaugeHigh : null;
      const min = vh.gaugeLow !== 0 ? vh.gaugeLow : null;
      let value = 0;
      if (!isNaN(currentVal)) {
        if (Number(currentVal) > (min ? min : 3)) {
          if (Number(currentVal) > (max ? max : 95)) {
            value = +(currentVal - (max ? 0.5 : 20)).toFixed(2);
          } else {
            if (randomSymbol === 1) {
              value = +(currentVal + randomNumber).toFixed(2);
            } else {
              value = +(currentVal - randomNumber).toFixed(2);
            }
          }
        } else {
          value = +(currentVal + (min ? 0.5 : 20)).toFixed(2);
        }
      }
      newVh = { ...newVh, value: value.toString() };
      const deviceState = this.updateAlert(newVh, deviceIndex, deviceId, value.toString());
      if (deviceState) {
        newDevice.state = deviceState;
        demoAlertController.updateAlertConfig({ state: deviceState }, newDevice.id);
      }
      newValueHolders.push(newVh);
    });
    newDevice = { ...newDevice, valueHolders: newValueHolders };
    this._devices[deviceIndex] = newDevice;

    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
  };

  randomUpdateValueHolder = (deviceId: string, vhId: number): void => {
    const deviceIndex = this._devices.findIndex(device => device.id === deviceId);
    if (deviceIndex === -1) {
      return;
    }
    const minInterval = 0.1;
    const maxInterval = 0.9;
    const newDevice: VesselDevice = this._devices[deviceIndex];
    const vhIndex = newDevice.valueHolders.findIndex(vh => vh.id === vhId);
    if (vhIndex === -1) {
      return;
    }
    const vh = newDevice.valueHolders[vhIndex];
    const regex = /.*\..*\..*/;
    const containsDict = vh.dictionary && Object.keys(vh.dictionary).length > 0;
    if (isNaN(Number(vh.value)) || regex.test(vh.value) || containsDict || vh.controllable) {
      return;
    }
    const currentVal = Number(vh.value);
    const randomSymbol = getRandomInt(1, 2);

    const randomNumber = getRandom(minInterval, maxInterval, 2);
    const max = vh.gaugeHigh !== 0 ? vh.gaugeHigh : null;
    const min = vh.gaugeLow !== 0 ? vh.gaugeLow : null;
    let value = 0;
    if (!isNaN(currentVal)) {
      if (Number(currentVal) > (min ? min : 3)) {
        if (Number(currentVal) > (max ? max : 95)) {
          value = +(currentVal - (max ? 0.5 : 20)).toFixed(2);
        } else {
          if (randomSymbol === 1) {
            value = +(currentVal + randomNumber).toFixed(2);
          } else {
            value = +(currentVal - randomNumber).toFixed(2);
          }
        }
      } else {
        value = +(currentVal + (min ? 0.5 : 20)).toFixed(2);
      }
    }
    const newVh = { ...vh, value: value.toString() };
    newDevice.valueHolders[vhIndex] = newVh;
    const deviceState = this.updateAlert(newVh, deviceIndex, deviceId, value.toString());

    if (deviceState) {
      this._devices[deviceIndex].state = deviceState;
      demoAlertController.updateAlertConfig({ state: deviceState }, this._devices[deviceIndex].id);
    }
    this._devices[deviceIndex] = newDevice;
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(this._devices));
  };
}

export default DemoDevicesController;

class DemoGraphController {
  generateData = (
    dateFrom: string,
    dateTo: string,
    device: SimpleDeviceFieldWrapper
  ): ValueResponse[] => {
    const dif: number = +new Date(dateTo) - +new Date(dateFrom);
    const hoursDifference = Math.floor(dif / 1000 / 60 / 60);
    let dateRange = 'LAST_HOUR';
    if (hoursDifference < 2) {
      dateRange = 'LAST_HOUR';
    } else if (hoursDifference <= 25) {
      dateRange = 'LAST_DAY';
    } else if (hoursDifference <= 146) {
      dateRange = 'LAST_7_DAYS';
    }
    const graphLogs = getDemoGraphData(dateRange as DateFilters);
    const timestamps = this.changeTimestamps(dateRange, graphLogs);
    const values = this.generateRandomData(device, graphLogs);
    const newLog: ValueResponse[] = [];
    for (let i = 0; i < timestamps.length - 1; i++) {
      newLog.push({
        average: values.values[i] ?? 0,
        max: values.minMax[i] ? values.minMax[i].max : 0,
        min: values.minMax[i] ? values.minMax[i].min : 0,
        timestamp: timestamps[i],
      });
    }
    return newLog;
  };

  generateRandomData = (
    data: SimpleDeviceFieldWrapper,
    logs: ValueResponse[]
  ): { values: number[]; minMax: { min: number; max: number }[] } => {
    const length = logs.length;
    let newValues = [];
    const newMinMax = [];

    const max = data.gaugeHigh ? data.gaugeHigh : Number(data.value) + 40;
    const step = (max - data.gaugeLow) / length;
    if (data.fieldName === 'chargeLevel') {
      const decrementalArray = Array.from(
        { length: (max - data.gaugeLow) / step + 1 },
        (_, i) => data.gaugeLow + i * step
      );
      newValues = decrementalArray.reverse();
      newValues.forEach(value => {
        const randMin = this.getRandom(value - 2, value, 2);
        const randMax = this.getRandom(value, value + 2, 2);
        newMinMax.push({ min: randMin, max: randMax });
      });
    } else {
      for (let i = 0; i < length; i++) {
        const randomNumber = this.getRandom(data.gaugeLow, max, 2);
        newValues.push(randomNumber);
        const randMin = this.getRandom(data.gaugeLow - 2, randomNumber - 0.5, 2);
        const randMax = this.getRandom(randomNumber + 0.5, max + 2, 2);
        newMinMax.push({ min: randMin, max: randMax });
      }
    }
    return { values: newValues, minMax: newMinMax };
  };

  changeTimestamps = (dateRange: string, logs: ValueResponse[]): string[] => {
    const length = logs.length;
    const newTimestamps: string[] = [];
    const currentTime = new Date();
    const firstTime = this.subtractTimeFromDate(
      currentTime,
      dateRange === 'LAST_HOUR' ? 1 : dateRange === 'LAST_DAY' ? 24 : 168
    );
    const difference = currentTime.valueOf() - firstTime.valueOf();
    const interval = difference / 1000 / 60 / length;

    newTimestamps.push(firstTime.toISOString());
    let currentNewVal = firstTime;
    for (let i = 0; i < length; i++) {
      currentNewVal = this.addMinutes(currentNewVal, interval);
      newTimestamps.push(currentNewVal.toISOString());
    }
    return newTimestamps;
  };

  subtractTimeFromDate = (objDate: Date, hours: number): Date => {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = hours * 60 * 60 * 1000;
    return new Date(numberOfMlSeconds - addMlSeconds);
  };

  addMinutes = (date: Date, minutes: number): Date => {
    return new Date(date.getTime() + minutes * 60000);
  };

  getRandom = (min: number, max: number, decimalPlaces: number): number => {
    const rand = Math.random() * (max - min) + min;
    const power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
  };
}
