import { Routine } from 'store/vesselRoutines/types';

import { createNewItemWithCustomId, storageUtil } from '../utility';

type DemoRoutine = Routine & { paused: boolean };

class DemoRoutineController {
  private _routines: DemoRoutine[] = [];

  constructor() {
    const storageVessel = storageUtil('demo-vessel-routines', 'GET');
    if (storageVessel) {
      this._routines = JSON.parse(storageVessel);
    } else {
      this._routines = [];
    }
  }

  get routines(): DemoRoutine[] {
    return this._routines;
  }

  addRoutine = (data: Routine): void => {
    const newData: DemoRoutine = { ...data, paused: false };
    const routineIndex = this._routines.findIndex(routine => routine.id === data.id);
    if (routineIndex === -1) {
      this._routines = createNewItemWithCustomId(this._routines, newData, 'id', true);
    } else {
      this._routines[routineIndex] = { ...data, paused: this._routines[routineIndex].paused };
    }
    storageUtil('demo-vessel-routines', 'POST', JSON.stringify(this._routines));
  };

  deleteRoutine = (id: string): void => {
    this._routines = this._routines.filter(routine => routine.id !== id);
    storageUtil('demo-vessel-routines', 'POST', JSON.stringify(this._routines));
  };

  pauseRoutine = (routineId: string, state: boolean): void => {
    const routineIndex = this._routines.findIndex(routine => routine.id === routineId);
    if (routineIndex === -1) {
      return;
    }
    const newRoutines = [...this._routines];
    newRoutines[routineIndex] = { ...newRoutines[routineIndex], paused: state };
    this._routines = newRoutines;
    storageUtil('demo-vessel-routines', 'POST', JSON.stringify(this._routines));
  };
}

export default DemoRoutineController;
