import {
  UserNotification,
  UserNotificationEvent,
} from 'views/DashboardRouter/components/UserProfile';

import { demoVesselController } from '../provider';
import { createNewItemWithCustomId, storageUtil } from '../utility';

class DemoNotificationsController {
  private _notifications: UserNotification[] = [];

  constructor() {
    const storageVessel = storageUtil('demo-vessel-notifications', 'GET');
    if (storageVessel) {
      this._notifications = JSON.parse(storageVessel);
    } else {
      this._notifications = [];
    }
  }

  get notifications(): UserNotification[] {
    return this._notifications;
  }

  createNotification = (eventText: string): void => {
    const id = isNaN(Number(demoVesselController.vessel?.id))
      ? Number(demoVesselController.vessel?.id) ?? 1
      : 1;
    if (this.notifications.length === 0) {
      const newNotification: UserNotification = {
        id: 1,
        timestamp: new Date().toISOString(),
        acknowledged: false,
        notificationEvents: [
          {
            id: 1,
            timestamp: new Date().toISOString(),
            eventText: eventText,
          },
        ],
        vesselName: demoVesselController.vessel?.name ?? 'Demo Vessel',
        vesselId: id,
      };
      this._notifications = [newNotification];
      storageUtil('demo-vessel-notifications', 'POST', JSON.stringify(this._notifications));
      return;
    }
    const newNotificationEvents = this.prevNotificationCheck(eventText);
    if (newNotificationEvents.length <= 1) {
      const newNotification: Partial<UserNotification> = {
        timestamp: new Date().toISOString(),
        acknowledged: false,
        notificationEvents: newNotificationEvents,
        vesselName: demoVesselController.vessel?.name ?? 'Demo Vessel',
        vesselId: id,
      };
      this._notifications = createNewItemWithCustomId(
        this._notifications,
        newNotification,
        'id'
      ) as UserNotification[];
      storageUtil('demo-vessel-notifications', 'POST', JSON.stringify(this._notifications));
      return;
    }

    const prevNotification = this._notifications[this._notifications.length - 1];
    this._notifications[this._notifications.length - 1] = {
      id: prevNotification.id,
      timestamp: new Date().toISOString(),
      acknowledged: false,
      notificationEvents: newNotificationEvents,
      vesselName: demoVesselController.vessel?.name ?? 'Demo Vessel',
      vesselId: id,
    };
    storageUtil('demo-vessel-notifications', 'POST', JSON.stringify(this._notifications));
  };

  prevNotificationCheck = (msg: string): UserNotificationEvent[] => {
    const prevNotification = this._notifications[this._notifications.length - 1];
    const notificationEvents = prevNotification.notificationEvents;
    const sameNotification = this.checkWithin5Mins(
      notificationEvents[notificationEvents.length - 1].timestamp
    );
    const newNotificationEvent: Partial<UserNotificationEvent> = {
      timestamp: new Date().toISOString(),
      eventText: msg,
    };
    if (sameNotification) {
      return createNewItemWithCustomId(
        notificationEvents,
        newNotificationEvent,
        'id'
      ) as UserNotificationEvent[];
    }
    return [
      {
        ...newNotificationEvent,
        id: 1,
      } as UserNotificationEvent,
    ];
  };

  checkWithin5Mins = (timestampStr: string): boolean => {
    const timestamp = new Date(timestampStr).getTime();
    const currentTime = new Date().getTime();
    const dif = currentTime - timestamp;
    return dif <= 300000;
  };

  clearNotifications = (): void => {
    this._notifications = [];
    storageUtil('demo-vessel-notifications', 'POST', JSON.stringify(this._notifications));
  };
}

export default DemoNotificationsController;
