import { AlertPaginatedType, GetVesselAuditPaginatedInterface } from 'api/selectedVessel';
import { ValueHolder } from 'store/selectedDevice/types';
import { VesselLog } from 'store/selectedVessel/types';
import { VesselAlert } from 'store/types';
import { AlertLog, AlertType, Status } from 'types/logs';

import { Condition } from '../../store/vesselRoutines/types';
import { defaultAlertConfig } from '../dataImporter';
import { demoAlertController, demoDevicesController } from '../provider';
import { createNewItemWithCustomId, getParsedValue, storageUtil } from '../utility';

interface CustomAlertI {
  id: string;
  name: string;
  message: string;
  alertLevel: string;
  notifyUsers: string[];
  matchAllConditions: boolean;
  conditions: Condition[];
  repeatOptions: string;
}

export interface PropertyAlertConfig {
  deviceId: string;
  propertyId: number;
  instant: boolean;
  warnLevelHigh: string;
  warnLevelLow: string;
  criticalLevelHigh: string;
  criticalLevelLow: string;
  state: string;
  lastTriggeredValue: string;
  ackTimestamp: string | null;
  ackUserId: string | null;
  ackUserName: string;
  acknowledged: boolean;
  alertDelayEnable: boolean;
  alertDelayMs: number;
  alertMessage: string;
  repeatOptions: string;
  silenceTimestamp: string | null;
  silenceUserId: string | null;
  silenceUsername: string;
  silenced: boolean;
  instantAlertLevel: string;
}

class DemoAlertController {
  private _alerts: VesselAlert[] = [];
  private _customAlerts: CustomAlertI[] = [];
  private _propertyAlertConfigs: PropertyAlertConfig[] = [];

  private _logs: AlertLog[] = [];
  private _status: string | null = null;
  private _time = 1;

  constructor() {
    const storageVessel = storageUtil('demo-vessel-alert-logs', 'GET');
    if (storageVessel) {
      this._logs = JSON.parse(storageVessel);
    } else {
      this._logs = require('../data/logs/Week/AlertLogs.json');
    }
    const customAlertsStorage = storageUtil('demo-vessel-custom-alerts', 'GET');
    if (customAlertsStorage) {
      this._customAlerts = JSON.parse(customAlertsStorage);
    }
    const existingPropertyAlertConfigs = storageUtil(
      'demo-vessel-valueholders-alert-configs',
      'GET'
    );
    if (existingPropertyAlertConfigs) {
      this._propertyAlertConfigs = JSON.parse(existingPropertyAlertConfigs);
    }
  }

  get alerts(): VesselAlert[] {
    const storageVessel = storageUtil('demo-vessel-alerts', 'GET');
    if (storageVessel) {
      this._alerts = JSON.parse(storageVessel);
    } else {
      if (this._propertyAlertConfigs && this._propertyAlertConfigs.length > 0) {
        this._alerts = this._propertyAlertConfigs.map(propAlertConfig => {
          const device = demoDevicesController.devices.find(
            device => device.id === propAlertConfig.deviceId
          );
          const property = device?.valueHolders.find(vh => vh.id === propAlertConfig.propertyId);
          return {
            alertType: 'PROPERTY',
            state: propAlertConfig.state,
            id: `prop_${propAlertConfig.propertyId}`,
            name: property ? property.fullName : '',
            message: propAlertConfig.alertMessage,
            currentValue: property ? getParsedValue(property) : '0',
            lastTriggered: new Date().toISOString(),
            acknowledged: false,
            ackUsername: null,
            ackTimestamp: null,
            deviceId: propAlertConfig.deviceId ?? '1',
            silenced: false,
            silenceUsername: null,
            silenceTimestamp: null,
            description: '',
            notify: false,
            alert: true,
            fired: true,
            lastStateChange: new Date(),
            remind: false,
            remindMinutes: 0,
            notifyUsers: [],
            matchAllConditions: false,
          };
        });
        storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
      }
    }
    return this._alerts;
  }

  set status(status: string | null) {
    this._status = status;
  }

  set time(time: number) {
    this._time = time;
  }

  get logs(): AlertLog[] {
    const storageVessel = storageUtil('demo-vessel-alert-logs', 'GET');
    if (storageVessel) {
      this._logs = JSON.parse(storageVessel);
    } else {
      this._logs = require('../data/logs/Week/AlertLogs.json');
    }
    return this._logs;
  }

  get propertyAlertConfigs(): PropertyAlertConfig[] {
    return this._propertyAlertConfigs;
  }

  createNewAlertLog = (type: AlertType, alert: VesselAlert, valueHolder?: ValueHolder): void => {
    const logs = this.logs;
    const newLog: AlertLog = {
      ackTimestamp: '',
      ackUsername: '',
      acknowledged: false,
      alertType: type,
      deviceName: valueHolder?.fullName ?? valueHolder?.name ?? '',
      id: (+new Date()).toString(),
      message: alert.message,
      name: alert.name,
      note: '',
      status: alert.state as Status,
      triggered: new Date().toISOString(),
    };
    this._logs = [newLog, ...logs];
    storageUtil('demo-vessel-alert-logs', 'POST', JSON.stringify(this._logs));
  };

  retrievePage = (data: AlertPaginatedType) => {
    demoAlertController.status = data.status;
    this._time = this.calcDifference(data.dateFrom, data.dateTo);
    const logItems = this.logs;
    const logController = new LogsController();
    const page = logController.retrievePage(logItems, data, 'triggered') as AlertLog[];
    return logController.mergeAlertLogData(page, data);
  };

  retrieveCustomAlert = (alertId: string): CustomAlertI | null => {
    const alertFound = this._customAlerts.find(customAlert => customAlert.id === alertId);
    if (alertFound) {
      return alertFound;
    }
    return null;
  };

  calcDifference = (start: string, end: string): number => {
    const diff = +new Date(end) - +new Date(start);
    return Math.floor(diff / 1000 / 60 / 60);
  };

  calcAlertMsg = (state: string, defaultMessage: string, vh?: ValueHolder): string => {
    if (!vh) {
      return defaultMessage;
    }
    if (vh.dictionary && Object.keys(vh.dictionary).length > 0 && vh.value !== undefined) {
      return `${vh.name} ${vh.dictionary[Number(vh.value)]}`;
    }
    if (state === 'WARNING') {
      if (vh.value <= vh.warnLevelLow) {
        if (vh.warnTextLow) {
          return vh.warnTextLow;
        }
      } else {
        if (vh.warnTextHigh) {
          return vh.warnTextHigh;
        }
      }
    } else if (state === 'CRITICAL') {
      if (vh.value <= vh.criticalLevelLow) {
        if (vh.criticalTextLow) {
          return vh.criticalTextLow;
        }
      } else {
        if (vh.criticalTextHigh) {
          return vh.criticalTextHigh;
        }
      }
    }
    return defaultMessage;
  };

  createCustomAlert = (payload: Partial<CustomAlertI>): void => {
    const newCustomAlerts = createNewItemWithCustomId(this._customAlerts, payload, 'id', true);
    this._customAlerts = newCustomAlerts as CustomAlertI[];
    storageUtil('demo-vessel-custom-alerts', 'POST', JSON.stringify(this._customAlerts));
    this.createNewAlert(
      'CUSTOM',
      'cus_' + newCustomAlerts[newCustomAlerts.length - 1].id,
      payload.name ?? 'Custom Alert',
      payload.message ?? '',
      '',
      payload.alertLevel ?? 'NORMAL' //Change to actual state
    );
  };

  updateCustomAlert = (alertId: string, payload: Partial<CustomAlertI>): void => {
    const alertIndexFound = this._customAlerts.findIndex(customAlert => customAlert.id === alertId);
    if (alertIndexFound === -1) {
      return;
    }
    const notifyUsers = payload.notifyUsers?.filter(user => user !== undefined);
    this._customAlerts[alertIndexFound] = {
      ...payload,
      id: alertId,
      notifyUsers,
    } as CustomAlertI;
    storageUtil('demo-vessel-custom-alerts', 'POST', JSON.stringify(this._customAlerts));
    this.createNewAlert(
      'CUSTOM',
      `cus_${alertId}`,
      payload.name ?? 'Custom Alert',
      payload.message ?? '',
      '',
      payload.alertLevel ?? 'NORMAL' //Change to actual state
    );
  };

  pauseCustomAlert = (alertId: string, paused: boolean): void => {
    const alertIndexFound = this._alerts.findIndex(alert => alert.id === `cus_${alertId}`);
    if (alertIndexFound === -1) {
      return;
    }
    const newAlerts = [...this._alerts];
    newAlerts[alertIndexFound] = {
      ...newAlerts[alertIndexFound],
      paused: paused,
    } as VesselAlert & { paused: boolean };
    this._alerts = newAlerts;
    storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
  };

  deleteCustomAlert = (alertId: string): void => {
    this._customAlerts = this._customAlerts.filter(alert => alert.id !== alertId);
    storageUtil('demo-vessel-custom-alerts', 'POST', JSON.stringify(this._customAlerts));
    this.removeAlert(`cus_${alertId}`);
  };

  createNewAlert = (
    type: string,
    id: string,
    name: string,
    msg: string,
    value: string,
    state: string,
    vh?: ValueHolder,
    deviceId?: string
  ): void => {
    const newMessage = this.calcAlertMsg(state, msg, vh);
    const newAlert: VesselAlert = {
      alertType: type,
      state: state,
      id: id,
      name: name,
      message: newMessage,
      currentValue: value, //Might not be needed.
      lastTriggered: new Date().toISOString(),
      acknowledged: false,
      ackUsername: null,
      ackTimestamp: null,
      deviceId: deviceId ?? '1',
      silenced: false,
      silenceUsername: null,
      silenceTimestamp: null,
      description: '',
      notify: false,
      alert: true,
      fired: true,
      lastStateChange: new Date(),
      remind: false,
      remindMinutes: 0,
      notifyUsers: [],
      matchAllConditions: false,
    };
    const alertIndex = this._alerts.findIndex(alert => alert.id === id);
    if (alertIndex === -1) {
      this._alerts = [...this._alerts, newAlert];
    } else {
      const newAlerts = [...this._alerts];
      newAlerts[alertIndex] = { ...newAlert };
      this._alerts = newAlerts;
    }
    storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
    this.createNewAlertLog(type as AlertType, newAlert, vh);
  };

  removeAlert = (id: string): void => {
    this._alerts = this._alerts.filter(alert => alert.id !== id);
    storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
  };

  silenceAlert = (alertId: string): void => {
    const alertIndex = this._alerts.findIndex(alert => alert.id === alertId);
    if (alertIndex === -1) {
      return;
    }
    const newAlerts = [...this._alerts];
    newAlerts[alertIndex] = {
      ...newAlerts[alertIndex],
      silenced: true,
      silenceTimestamp: new Date().toISOString(),
      silenceUsername: 'Demo User',
    };
    this._alerts = newAlerts;
    storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
  };

  ackAlert = (alertId: string): void => {
    const alertIndex = this._alerts.findIndex(alert => alert.id === alertId);
    if (alertIndex === -1) {
      return;
    }
    const newAlerts = [...this._alerts];
    newAlerts[alertIndex] = {
      ...newAlerts[alertIndex],
      acknowledged: true,
      ackTimestamp: new Date().toISOString(),
      ackUsername: 'Demo User',
    };
    this._alerts = newAlerts;
    storageUtil('demo-vessel-alerts', 'POST', JSON.stringify(this._alerts));
  };

  mergeAlertLogData = (logItems: AlertLog[], data: AlertPaginatedType) => {
    const startIndex = data.page * data.size;
    const endIndex = data.page * data.size + data.size;
    const items = data.status
      ? logItems.filter(logItem => {
          if (data.status === 'WARNING') {
            return logItem.status === 'WARNING';
          } else if (data.status === 'CRITICAL') {
            return logItem.status === 'CRITICAL';
          }
          return false;
        })
      : logItems;
    return {
      content: items.slice(startIndex, endIndex),
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageNumber: data.page,
        pageSize: data.size,
        unpaged: false,
        paged: true,
      },
      last: false,
      totalElements: logItems.length,
      totalPages: (logItems.length - 1) / data.size,
      number: 0,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      size: 10,
      first: true,
      numberOfElements: 10,
      empty: false,
    };
  };

  updateAlertConfig = (payload: any, deviceId: string): void => {
    const alertConfigIndexFound = this._propertyAlertConfigs.findIndex(
      alertConfig => alertConfig.propertyId === payload.propertyId
    );
    if (alertConfigIndexFound === -1) {
      const newAlertConfig = {
        ...defaultAlertConfig,
        ...payload,
        //TODO: deviceId might not be needed.
        deviceId: deviceId,
      };
      this._propertyAlertConfigs = [...this._propertyAlertConfigs, newAlertConfig];
      storageUtil(
        'demo-vessel-valueholders-alert-configs',
        'POST',
        JSON.stringify(this._propertyAlertConfigs)
      );
      return;
    }
    this._propertyAlertConfigs[alertConfigIndexFound] = {
      ...this._propertyAlertConfigs[alertConfigIndexFound],
      ...payload,
      //TODO: deviceId might not be needed.
      deviceId: deviceId,
    };
    storageUtil(
      'demo-vessel-valueholders-alert-configs',
      'POST',
      JSON.stringify(this._propertyAlertConfigs)
    );
  };
}

export default DemoAlertController;

export class LogsController {
  retrievePage = (
    logs: AlertLog[] | VesselLog[],
    data: AlertPaginatedType | GetVesselAuditPaginatedInterface,
    timestampKey: 'triggered' | 'timestamp'
  ): AlertLog[] | VesselLog[] => {
    return [
      // @ts-ignore
      ...logs.filter((log: AlertLog | VesselLog) => {
        const logDateRetrieved =
          // @ts-ignore
          log[timestampKey] ?? this.subtractTimeFromDate(new Date(), 24).toISOString();
        const dateToRetrieved = data.dateTo;
        const logDate = +new Date(logDateRetrieved);
        let fromDate = +new Date(data.dateFrom);
        let toDate = +new Date(dateToRetrieved);
        if ('timeFrame' in data && data.timeFrame) {
          toDate = +new Date();
          let time = this.subtractTimeFromDate(new Date(), 1);
          switch (data.timeFrame) {
            case 1:
              fromDate = +time;
              break;
            case 4:
              time = this.subtractTimeFromDate(new Date(), 4);
              fromDate = +time;
              break;
            case 6:
              time = this.subtractTimeFromDate(new Date(), 6);
              fromDate = +time;
              break;
          }
        }
        if (!fromDate && !toDate) {
          return true;
        }
        const dateFromRetrieved = data.dateFrom
          ? data.dateFrom
          : this.subtractTimeFromDate(new Date(), 24).toISOString();
        const dateTo = data.dateTo
          ? data.dateTo
          : this.subtractTimeFromDate(new Date(), 24).toISOString();
        return logDate > +new Date(dateFromRetrieved) && logDate < +new Date(dateTo);
      }),
    ];
  };

  mergeAlertLogData = (
    logItems: AlertLog[] | VesselLog[],
    data: AlertPaginatedType | GetVesselAuditPaginatedInterface
  ) => {
    const startIndex = data.page * data.size;
    const endIndex = data.page * data.size + data.size;
    const items =
      'status' in data && data.status
        ? (logItems as AlertLog[]).filter((logItem: AlertLog) => {
            if (data.status === 'WARNING') {
              return logItem.status === 'WARNING';
            } else if (data.status === 'CRITICAL') {
              return logItem.status === 'CRITICAL';
            }
            return false;
          })
        : logItems;

    return {
      content: items.slice(startIndex, endIndex),
      pageable: {
        sort: {
          empty: true,
          sorted: false,
          unsorted: true,
        },
        offset: 0,
        pageNumber: data.page,
        pageSize: data.size,
        unpaged: false,
        paged: true,
      },
      last: false,
      totalElements: logItems.length,
      totalPages: (logItems.length - 1) / data.size,
      number: 0,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      size: 10,
      first: true,
      numberOfElements: 10,
      empty: false,
    };
  };

  subtractTimeFromDate = (objDate: Date, hours: number): Date => {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = hours * 60 * 60 * 1000;
    return new Date(numberOfMlSeconds - addMlSeconds);
  };
}
