import { Controller, ControllerInterface, DeviceCategory } from '../../store/selectedVessel/types';
import { SimpleDevice } from '../../types/simpleDevices';
import { demoCamerasController, demoSimpleDevicesController } from '../provider';
import { StorageKey } from '../types';
import { createNewItemWithCustomId, createNewItemWithId, storageUtil } from '../utility';

class DemoAccessController {
  private _controllers: Controller[] = [];
  private _interfaces: ControllerInterface[] = [];

  constructor() {
    if (this._controllers.length === 0) {
      this._controllers = require('demo/data/access/Interfaces.json');
    }
    const storageVessel = storageUtil('demo-vessel-appsettings', 'GET');
    if (storageVessel) {
      this._interfaces = JSON.parse(storageVessel);
    } else {
      this._interfaces = require('demo/data/access/AppSettings.json');
    }
  }

  get controllers(): Controller[] {
    return this._controllers;
  }

  get interfaces(): ControllerInterface[] {
    return this._interfaces;
  }

  generateInterfaceTemplate = (): DeviceCategory[] => {
    const locations = demoSimpleDevicesController.locations;
    const newDefaultInterfaceCategories: any[] = [];
    this._interfaces[0].categories.forEach(category => {
      let name = category.name.toUpperCase();
      if (name === 'MARINE') {
        name = 'MARITIME';
      } else if (name === 'TANKS') {
        name = 'TANK';
      }
      const devices = storageUtil(`demo-vessel-simpledevice-${name}` as StorageKey, 'GET');
      const newLocations: any[] = [];
      let newDevices: SimpleDevice[] = [];
      if (devices) {
        newDevices = JSON.parse(devices);
      }
      if (category.name === 'Link') {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [
            {
              allowed: 'NONE',
              name: 'Link',
              devices: [
                { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: 'NONE', selectable: true },
              ],
            },
          ],
          devices: [
            { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: 'NONE', selectable: true },
          ],
        });
        return;
      } else if (category.name === 'Cameras') {
        const cameras = demoCamerasController.cameras;
        const devices = [
          ...cameras.map(camera => {
            return {
              location: camera.location,
              name: camera.name,
              id: camera.id,
              warningState: 'NORMAL',
              manufacturer: 'null',
              model: 'null',
              serial: 'null',
              system: 'CAMERA',
              subSystem: 'CCTV',
              controllable: false,
              online: true,
              displayComponent: 'camera',
              fields: [],
              selectable: true,
              state: 'NORMAL',
              templateName: 'Camera',
              metaData: { capacity: '' },
              friendlyName: camera.name,
            } as SimpleDevice;
          }),
        ];
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: category.locations,
          devices: devices,
        });
        return;
      }
      if (newDevices.length === 0) {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [],
          devices: [],
        });
        return;
      }
      if (devices) {
        locations.forEach(location => {
          const locationDevices = newDevices.filter(device => device.location === location);
          if (locationDevices.length > 0) {
            newLocations.push({
              allowed: 'NONE',
              name: location,
              devices: locationDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: 'NONE',
                  selectable: true,
                };
              }),
            });
          }
        });
      }
      const newCategory = {
        name: category.name,
        allowed: 'NONE',
        locations: newLocations,
        devices:
          newDevices && newDevices.length > 0
            ? newDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: 'NONE',
                  selectable: true,
                };
              })
            : [],
      };
      newDefaultInterfaceCategories.push(newCategory);
    });
    return newDefaultInterfaceCategories;
  };

  generateInterface = (empty?: boolean): ControllerInterface[] => {
    const locations = demoSimpleDevicesController.locations;
    const newDefaultInterfaceCategories: any[] = [];
    this._interfaces[0].categories.forEach(category => {
      let name = category.name.toUpperCase();
      if (name === 'MARINE') {
        name = 'MARITIME';
      } else if (name === 'TANKS') {
        name = 'TANK';
      }
      const devices = storageUtil(`demo-vessel-simpledevice-${name}` as StorageKey, 'GET');
      const newLocations: any[] = [];
      let newDevices: SimpleDevice[] = [];
      if (devices) {
        newDevices = JSON.parse(devices);
      }
      if (category.name === 'Link') {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: category.allowed,
          locations: [
            {
              allowed: 'ALL',
              name: 'Link',
              devices: [
                { id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: true, selectable: true },
              ],
            },
          ],
          devices: [{ id: 'LINK-BRIDGE-ID', name: 'LINKbridge', allowed: true, selectable: true }],
        });
        return;
      } else if (category.name === 'Cameras') {
        const cameras = demoCamerasController.cameras;
        const devices = [
          ...cameras.map(camera => {
            return {
              location: camera.location,
              name: camera.name,
              id: camera.id,
              warningState: 'NORMAL',
              manufacturer: 'null',
              model: 'null',
              serial: 'null',
              system: 'CAMERA',
              subSystem: 'CCTV',
              controllable: false,
              online: true,
              displayComponent: 'camera',
              fields: [],
              selectable: true,
              state: 'NORMAL',
              templateName: 'Camera',
              metaData: { capacity: '' },
              friendlyName: camera.name,
            } as SimpleDevice;
          }),
        ];
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: category.allowed, //demoCamerasController.cameras.length > 0 ? 'FULL' : 'NONE'
          locations: category.locations,
          devices: devices,
        });
        return;
      }
      if (newDevices.length === 0) {
        newDefaultInterfaceCategories.push({
          name: category.name,
          allowed: 'NONE',
          locations: [],
          devices: [],
        });
        return;
      }
      if (devices) {
        locations.forEach(location => {
          // if (location === 'Link') {
          //   newLocations.push({
          //     allowed: 'FULL',
          //     name: 'Link',
          //     devices: [],
          //   });
          // }
          const locationDevices = newDevices.filter(device => device.location === location);
          if (locationDevices.length > 0) {
            const categoryLocation = category.locations.find(
              catLocation => catLocation.name === location
            );
            newLocations.push({
              allowed: categoryLocation ? categoryLocation.allowed : 'NONE',
              name: location,
              devices: locationDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: !empty,
                  selectable: true,
                };
              }),
            });
          }
        });
      }
      const newCategory = {
        name: category.name,
        allowed: category.allowed,
        locations: newLocations,
        devices:
          newDevices && newDevices.length > 0
            ? newDevices.map(device => {
                return {
                  id: device.id,
                  name: device.name,
                  allowed: !empty,
                  selectable: true,
                };
              })
            : [],
      };
      newDefaultInterfaceCategories.push(newCategory);
    });
    this._interfaces = this._interfaces.map((interfaceItem, index) => {
      if (index === 0) {
        return { ...interfaceItem, categories: newDefaultInterfaceCategories };
      }
      return interfaceItem;
    });
    return this._interfaces;
  };

  createNewInterface = (data: ControllerInterface): void => {
    this._interfaces = createNewItemWithCustomId(this._interfaces, data, 'id', true);
    storageUtil('demo-vessel-appsettings', 'POST', JSON.stringify(this._interfaces));
  };

  deleteInterface = (interfaceId: string): void => {
    this._interfaces = this._interfaces.filter(interfaceItem => interfaceItem.id !== interfaceId);
    storageUtil('demo-vessel-appsettings', 'POST', JSON.stringify(this._interfaces));
  };

  updateInterface = (interfaceId: string, data: ControllerInterface): void => {
    const interfaceFound = this._interfaces.findIndex(
      interfaceItem => interfaceItem.id === interfaceId
    );
    if (interfaceFound === -1) {
      return;
    }
    const newInterfaces = [...this._interfaces];
    newInterfaces[interfaceFound] = data;
    this._interfaces = newInterfaces;
    storageUtil('demo-vessel-appsettings', 'POST', JSON.stringify(this._interfaces));
  };

  createController = (data: Controller): void => {
    this._controllers = createNewItemWithId(this._controllers, data, 'id');
  };

  updateController = (interfaceId: number, data: Controller): void => {
    const controllerIndex = this._controllers.findIndex(
      controller => controller.id === interfaceId
    );
    if (controllerIndex === -1) {
      return;
    }
    const newControllers = [...this._controllers];
    newControllers[controllerIndex] = data;
    this._controllers = newControllers;
  };

  removeController = (id: number): void => {
    this._controllers = this._controllers.filter(controller => controller.id !== id);
  };
}

export default DemoAccessController;
