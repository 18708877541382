import MapDisabled from 'assets/images/map-disabled-light.png';
import React from 'react';
import styled from 'styled-components';

type Props = {
  msg?: string;
  style?: React.CSSProperties;
};

const MapUnavailable: React.FC<Props> = props => {
  const { msg, style } = props;
  const lines = msg?.split('\n');
  return (
    <Wrapper style={style}>
      <MsgWrapper>
        {lines &&
          lines.map((line, index) => (
            <MsgItem key={`Map-Unavailable-line-${index}`}>{line}</MsgItem>
          ))}
      </MsgWrapper>
      <Overlay>
        <ImgWrapper src={MapDisabled} />
      </Overlay>
    </Wrapper>
  );
};

export default MapUnavailable;

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-height: 350px;
  background: #8ab4f8;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;

const MsgWrapper = styled.div`
  position: absolute;
`;

const MsgItem = styled.div`
  font-size: 20px;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
`;

const ImgWrapper = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  opacity: 0.5;
`;
