import { CancelToken } from 'axios';
import { Controller, ControllerInterface } from 'store/selectedVessel/types';
import { Condition, Routine } from 'store/vesselRoutines/types';
import { CameraDTO } from 'types/security';

import {
  demoAccessController,
  demoAlertController,
  demoCamerasController,
  demoDevicesController,
  demoDocumentsController,
  demoGeofenceController,
  demoRoutinesController,
  demoUserController,
  demoVesselController,
} from '../demo/provider';
import { MyResponse, RequestConfig, requestMiddleware } from './request';

export type AlertPaginatedType = {
  page: number;
  size: number;
  search: string;
  sortColumn: string;
  sortDesc?: boolean;
  dateFrom: string;
  dateTo: string;
  timeFrame: string | number | null;
  status: string | null;
};

export async function getVesselForId(id: string, cancelToken?: CancelToken): Promise<MyResponse> {
  return requestMiddleware(demoVesselController.vessel);
}

export async function getVesselDevices(id: string, cancelToken?: CancelToken): Promise<MyResponse> {
  return requestMiddleware(demoDevicesController.retrieveDevicesFull());
}

export async function getVesselDevicesBasic(
  id: string,
  cancelToken?: CancelToken
): Promise<MyResponse> {
  return requestMiddleware(demoDevicesController.retrieveBasic());
}

export async function getDeviceAlertsLogs(deviceId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/alertLogs`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getDeviceAlertsLogsPaginated(
  deviceId: string,
  data: AlertPaginatedType
): Promise<MyResponse> {
  const logItems = demoDevicesController.retrieveAlertLogs();
  const logs = demoAlertController.mergeAlertLogData(logItems, data);
  return requestMiddleware(logs);
}

export async function getDeviceAlertLog(
  deviceId: string,
  payload: {
    logId: string;
    note: string;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/alertLogs`,
    method: 'POST',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function getVesselAlerts(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoAlertController.alerts);
}

export async function ackAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  demoAlertController.ackAlert(alertId);
  return requestMiddleware({});
}

export async function silenceAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  demoAlertController.silenceAlert(alertId);
  return requestMiddleware({});
}

export async function getVesselRoutines(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoRoutinesController.routines);
}

export async function getIsVesselOnline(vesselId: string | null): Promise<MyResponse> {
  return requestMiddleware({ message: '', status: true });
}

export async function getVesselDirectory(vesselId: string): Promise<MyResponse> {
  return requestMiddleware([
    {
      userId: 'demo-user-id',
      name: 'Demo User',
      userType: 'Owner',
      corporate: false,
    },
  ]);
}

export async function getVesselNotifyDirectory(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoUserController.retrieveGeofenceUsers());
}

export async function getVesselDocuments(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoDocumentsController.documents);
}

export async function getVesselPermissions(vesselId: string): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(demoUserController.users);
}

export async function getControllers(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoAccessController.controllers);
}

export async function getInterfaceTemplate(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({ categories: demoAccessController.generateInterfaceTemplate() });
}

export async function getInterfaces(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoAccessController.generateInterface(false));
}

export async function postNewInterface(
  vesselId: string,
  data: ControllerInterface
): Promise<MyResponse> {
  demoAccessController.createNewInterface(data);
  return requestMiddleware({});
}

export async function putUpdateInterface(
  vesselId: string,
  interfaceId: string,
  data: ControllerInterface
): Promise<MyResponse> {
  demoAccessController.updateInterface(interfaceId, data);
  return requestMiddleware({});
}

export async function deleteInterfaceObject(
  vesselId: string,
  interfaceId: string
): Promise<MyResponse> {
  demoAccessController.deleteInterface(interfaceId);
  return requestMiddleware({});
}

export async function postUploadInterfaceImage(
  vesselId: string,
  interfaceId: string,
  InterfaceImage: FormData,
  darkMode: boolean,
  portrait: boolean
): Promise<MyResponse> {
  return requestMiddleware({});
}

export async function getRevealToken(vesselId: string, controllerId: number): Promise<MyResponse> {
  return requestMiddleware({ message: 'FP83NW19', status: true });
}

export async function getVesselWeather(lat: string, lon: string): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(require('demo/data/Weather.json'));
}

export async function getVesselAudit(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/logs/all`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export interface GetVesselAuditPaginatedInterface {
  page: number;
  size: number;
  search: string;
  sortColumn: string;
  sortDesc: boolean;
  dateFrom: string;
  dateTo: string;
  messageType: any;
}

export async function getVesselAuditPaginated(
  data: GetVesselAuditPaginatedInterface,
  vesselId: string
): Promise<MyResponse> {
  const logs = demoVesselController.retrievePage(data);
  return requestMiddleware(logs);
}

export async function getVesselNotes(id: string): Promise<MyResponse> {
  return requestMiddleware(demoVesselController.notes);
}

export async function getVesselBusList(id: string): Promise<MyResponse> {
  return requestMiddleware(demoDevicesController.buses);
}

export async function getBusProperties(id: string): Promise<MyResponse> {
  return requestMiddleware(demoDevicesController.retrieveBusProps(id));
}

export async function postVesselNote(
  messageText: string,
  vesselId: string | number
): Promise<MyResponse> {
  demoVesselController.addNewNote({
    creator: 'DEMO',
    timestamp: new Date().toLocaleString(),
    id: 0,
    note: messageText,
  });
  return requestMiddleware({});
}

export async function deleteVesselNote(
  noteId: string | number,
  vesselId: string | number
): Promise<MyResponse> {
  demoVesselController.deleteNote(noteId as number);
  return requestMiddleware({});
}

export async function postSetUserNotificationsForVessel(
  vesselId: string,
  state: boolean
): Promise<MyResponse> {
  demoVesselController.updateSubscriptionToggle('receiveNotifications', state);
  return requestMiddleware({});
}

export async function postSetVesselPropertyUpdateEnable(
  vesselId: string,
  state: boolean
): Promise<MyResponse> {
  demoVesselController.updateToggle('propertyUpdateEnable', state);
  return requestMiddleware({});
}

export async function postSetGpsReporting(vesselId: string, state: boolean): Promise<MyResponse> {
  demoVesselController.updateToggle('gpsReporting', state);
  return requestMiddleware({});
}

export async function postSetMfrAccess(vesselId: string, state: boolean): Promise<MyResponse> {
  demoVesselController.updateToggle('mfrAccess', state);
  return requestMiddleware({});
}

export async function startDiscoveryByBuses(
  vesselId: string,
  data: {
    messageSources: string[];
  }
): Promise<MyResponse> {
  demoVesselController.updateDiscovering(true);
  return requestMiddleware({});
}

export async function postSendVesselCommand(
  deviceProperty: number,
  value: number | string
): Promise<MyResponse> {
  demoDevicesController.updateDevicePropertyValue(deviceProperty, value);
  return requestMiddleware({});
}

export async function getVesselUserNotificationStatus(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/userNotifications`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselSubscription(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/subscription`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postUploadVesselDocument(
  formData: any,
  vesselId: string
): Promise<MyResponse> {
  demoDocumentsController.addDocument(formData);
  return requestMiddleware({});
}

export async function deleteVesselDocument(
  vesselId: string,
  downloadId: string
): Promise<MyResponse> {
  demoDocumentsController.deleteDocument(downloadId);
  return requestMiddleware({});
}

export async function pauseVesselRoutine(
  vesselId: string,
  routineId: string,
  state: boolean
): Promise<MyResponse> {
  demoRoutinesController.pauseRoutine(routineId, state);
  return requestMiddleware({});
}

export async function deleteVesselRoutine(
  vesselId: string,
  routineId: string
): Promise<MyResponse> {
  demoRoutinesController.deleteRoutine(routineId);
  return requestMiddleware({});
}

export async function postTestConditions(
  vesselId: string,
  matchAllConditions: boolean,
  conditions: Condition[]
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines/testConditions?matchAll=${matchAllConditions}`,
    method: 'POST',
    data: conditions,
  };
  return requestMiddleware(config);
}

export async function postRequestVesselAccess(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/requestAccess`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postDeleteVessel(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/delete?vesselId=${vesselId}`,
    method: 'POST',
  });
}
export async function postConfirmDeleteVessel(code: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/confirmDelete?code=${code}`,
    method: 'POST',
  });
}

export async function postFlipRoutine(data: {
  routineId: string;
  alert: boolean;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/routine/${data.routineId}/flip/${data.alert}`,
    method: 'POST',
  });
}

export async function getVesselProducts(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/products`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVoucherDetails(code: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/profile/billing/showVoucherDetails?code=${code}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateVesselSubscription(data: {
  vesselId: string;
  productIds: number[];
  paymentInterval: string;
  paymentMethodId: number;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/createVesselSubscription',
    method: 'POST',
    data: data,
  });
}

export async function postChangeVesselSubscription(data: {
  subscriptionId: number;
  productIds: number[];
  paymentInterval: string;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/changeSubscriptionProducts',
    method: 'POST',
    data: data,
  });
}

export async function postCreateVesselSubscriptionWithVoucher(data: any): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/createVesselSubscriptionWithVoucher',
    method: 'POST',
    data: data,
  });
}

export async function postUnlinkClient(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/unlinkClient`,
    method: 'POST',
  });
}

export async function postCompleteClientUnlink(code: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/completeClientUnlink?code=${code}`,
    method: 'POST',
  });
}

export async function postCreateRoutine2(vesselId: string, data: Routine): Promise<MyResponse> {
  demoRoutinesController.addRoutine(data);
  return requestMiddleware({});
}

export async function postCreateCustomAlert(
  vesselId: string,
  payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }
): Promise<MyResponse> {
  demoAlertController.createCustomAlert(payload);
  return requestMiddleware({});
}

export async function putUpdateCustomAlert(
  vesselId: string,
  alertId: string,
  payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }
): Promise<MyResponse> {
  demoAlertController.updateCustomAlert(alertId, payload);
  return requestMiddleware({});
}

export async function getEditCustomAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  return requestMiddleware(demoAlertController.retrieveCustomAlert(alertId));
}

export async function deleteCustomAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  demoAlertController.deleteCustomAlert(alertId);
  return requestMiddleware({});
}

export async function pauseVesselAlert(
  vesselId: string,
  alertId: string,
  state: boolean
): Promise<MyResponse> {
  demoAlertController.pauseCustomAlert(alertId, state);
  return requestMiddleware({});
}

export async function getAlertsLogsPaginated(
  vesselId: string,
  data: AlertPaginatedType
): Promise<MyResponse> {
  const logs = demoAlertController.retrievePage(data);
  // const logs = demoAlertController.mergeAlertLogData(logItems, data);
  return requestMiddleware(logs);
}

export async function getVesselLocationHistory(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/locationHistory`,
    method: 'GET',
  });
}

export async function getDocumentStorage(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoDocumentsController.documentStorage);
}

export async function getVesselGeofenceParams(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoGeofenceController.geofence);
}

export async function postSetGeoFenceState(
  vesselId: string,
  enabled: boolean
): Promise<MyResponse> {
  const enable = demoGeofenceController.changeState(enabled);
  return requestMiddleware(enable ? { status: 200 } : {});
}

export async function postSetGeoFenceParams(
  vesselId: string,
  radius: number,
  lat: string,
  lon: string,
  delay: number
): Promise<MyResponse> {
  demoGeofenceController.updateGeofenceParams(lat, lon, radius, delay);
  return requestMiddleware({});
}

export async function postSetGeofenceNotificationPref(
  vesselId: string,
  permissionId: string,
  enabled: boolean
): Promise<MyResponse> {
  demoUserController.updateGeofencePermission(permissionId, enabled);
  return requestMiddleware({});
}

export async function getCameras(vesselId: string): Promise<MyResponse> {
  return requestMiddleware(demoCamerasController.cameras);
}

export async function postAddCamera(vesselId: string, data: CameraDTO): Promise<MyResponse> {
  demoCamerasController.addCamera(data);
  return requestMiddleware({});
}

export async function putEditCamera(
  vesselId: string,
  id: number,
  data: CameraDTO
): Promise<MyResponse> {
  demoCamerasController.editCamera(id, data);
  return requestMiddleware({});
}

export async function deleteCamera(vesselId: string, id: number): Promise<MyResponse> {
  demoCamerasController.deleteCamera(id);
  return requestMiddleware({});
}

export async function postNewController(vesselId: string, data: Controller): Promise<MyResponse> {
  demoAccessController.createController(data);
  return requestMiddleware({ status: 200 });
}

export async function putUpdateController(
  vesselId: string,
  interfaceId: number,
  data: Controller
): Promise<MyResponse> {
  demoAccessController.updateController(interfaceId, data);
  return requestMiddleware({ status: 200 });
}

export async function deleteController(vesselId: string, id: number): Promise<MyResponse> {
  demoAccessController.removeController(id);
  return requestMiddleware({ status: 200 });
}

export async function getUserPerms(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    appSettingsId: 'wrap_guest',
    authorised: true,
    userManager: 'READ',
    deviceManager: 'WRITE',
    routineManager: 'WRITE',
    editVessel: true,
  });
}
