import { v4 as uuidv4 } from 'uuid';

import { VesselDevice } from '../../../../store/selectedDevice/types';
import { SimpleDevice, SimpleDeviceFieldWrapper } from '../../../../types/simpleDevices';
import { demoDevicesController } from '../../../provider';
import { StorageKey } from '../../../types';
import {
  createNewItemWithCustomId,
  getMetaDataValue,
  getParsedValue,
  storageUtil,
  systems,
} from '../../../utility';

export const generateSimpleDevices = (
  name: string,
  type: string,
  location: string
): SimpleDevice => {
  const newDevice = new SimpleDeviceGenerator(name, type, location).device;
  const devices = storageUtil(`demo-vessel-simpledevice-${newDevice.system}` as StorageKey, 'GET');
  let newDevices = [newDevice];
  if (devices) {
    newDevices = createNewItemWithCustomId(JSON.parse(devices) as SimpleDevice[], newDevice, 'id');
  }
  storageUtil(
    `demo-vessel-simpledevice-${newDevice.system}` as StorageKey,
    'POST',
    JSON.stringify(newDevices)
  );
  createNewLocation(newDevice.location);
  return newDevice;
};

const createNewLocation = (newLocation: string): void => {
  const locations = retrieveLocations();
  const locationFound = locations.find(location => location === newLocation);

  if (locationFound) {
    return;
  }
  storageUtil(
    'demo-vessel-simpledevice-locations',
    'POST',
    JSON.stringify([...locations, newLocation])
  );
};

export const retrieveLocations = (): string[] => {
  const locations = storageUtil('demo-vessel-simpledevice-locations', 'GET');
  let response = [];
  if (locations) {
    response = JSON.parse(locations);
  }
  return response;
};

export const mapProperty = (fieldId: number, propertyId: number | string): void => {
  let fieldFound: SimpleDeviceFieldWrapper | null = null;
  let systemFound = '';
  let devicesFound: SimpleDevice[] = [];
  systems.forEach(system => {
    //Break out of array if the device system has been found
    if (systemFound || !propertyId) {
      return;
    }
    const systemDevice = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
    if (systemDevice) {
      const devices = JSON.parse(systemDevice);
      if (devices.length === 0) {
        return;
      }
      (devices as SimpleDevice[]).forEach(device => {
        const fieldIndex = device.fields.findIndex(field => field.id === fieldId);
        if (fieldIndex === -1) {
          //Fallback to make sure only this system devices are added back to the same system.
          if (device.system === system) {
            devicesFound.push(device);
          }
          return;
        }
        const devices = demoDevicesController.devices;
        devices.forEach(advDevice => {
          const valueHolderFound = advDevice.valueHolders.find(field => field.id === propertyId);
          if (!valueHolderFound || fieldFound) {
            return;
          }
          systemFound = system;
          fieldFound = {
            ...device.fields[fieldIndex],
            displayName: valueHolderFound.name,
            value: valueHolderFound.value,
            unit: valueHolderFound.unit,
            parsedValue: getParsedValue(valueHolderFound),
            dpvhId: valueHolderFound.id,
            dpvhName: valueHolderFound.name,
            criticalLevelHigh: valueHolderFound.criticalLevelHigh,
            criticalLevelLow: valueHolderFound.criticalLevelLow,
            warnLevelLow: valueHolderFound.warnLevelLow,
            warnLevelHigh: valueHolderFound.warnLevelHigh,
            loggable: valueHolderFound.loggingEnabled,
            controllable: valueHolderFound.controllable,
            dictionary: valueHolderFound.dictionary as any,
            gaugeLow: valueHolderFound.gaugeLow,
            gaugeHigh: valueHolderFound.gaugeHigh,
            warningState: valueHolderFound.state,
          } as SimpleDeviceFieldWrapper;
          device.fields[fieldIndex] = fieldFound;
          devicesFound.push(device);
        });
      });
    }
    if (!systemFound) {
      devicesFound = [];
    }
  });
  if (fieldFound && devicesFound) {
    storageUtil(
      `demo-vessel-simpledevice-${systemFound}` as StorageKey,
      'POST',
      JSON.stringify(devicesFound)
    );
  }
};

class SimpleDeviceGenerator {
  private _device: SimpleDevice | null = null;
  private templates = require('demo/data/simpleDevices/templates.json');
  constructor(name: string, type: string, location: string) {
    const templateFound = this.templates.find((template: any) => template.name === type);
    let system = 'POWER';
    let subSystem = 'BATTERY';
    let controllable = true;
    let displayComponent = 'battery';
    let fields = [];
    if (templateFound) {
      system = templateFound.system;
      subSystem = templateFound.subSystem;
      controllable = templateFound.controllable;
      displayComponent = templateFound.displayComponent;
      fields = templateFound.fields.map((field: Partial<SimpleDeviceFieldWrapper>) => {
        return {
          ...field,
          id: uuidv4(),
          dpvhId: 0,
          dpvhName: '',
          loggable: false,
          fieldName: field.name,
          fieldOrder: 0,
          includeInGauges: field.fieldType === 'M_ENGINE_SPEED',
          metaField: field.metaField ?? false,
        };
      });
    }
    this._device = {
      id: 9,
      name: name,
      manufacturer: '',
      model: '',
      serial: '',
      system: system,
      subSystem: subSystem,
      controllable: controllable,
      online: true,
      displayComponent: displayComponent,
      location: location,
      warningState: 'NORMAL',
      fields: fields,
      templateName: name,
      state: 'NORMAL',
      metaData: { capacity: '' },
      friendlyName: name,
    };
  }

  get device(): SimpleDevice {
    return this._device as SimpleDevice;
  }
}
