// @ts-ignore
import classNames from 'classnames';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { BasicVessel } from 'store/vessels/types';
import VesselCard from 'views/VesselsView/components/VesselCard';

import { PinIcon } from '../DynamicSVGs/pins';
import { VesselState } from './types';
import styles from './VesselMarker.module.scss';
import { VesselType } from '../../views/CreateVesselView/components/VesselFormView/types';

type Props = {
  lat?: number;
  lng?: number;
  refLat?: number;
  refLng?: number;
  zoom?: number;
  state?: VesselState;
  vessel?: BasicVessel;
  tableMode?: boolean;
  onClick?: () => void;
  online?: boolean;
  type?: VesselType;
};

function VesselMarker(props: Props): ReactElement {
  const { lat, lng, refLat, refLng, zoom, state, vessel, onClick, tableMode, online } = props;
  const [cardPosStyles, setCardPosStyles] = useState<CSSProperties>({});

  useEffect(() => {
    cardPos();
  }, [zoom, refLat, refLng]);

  function cardPos(): void {
    let top: string | number = 'unset';
    let right: string | number = 'unset';
    let bottom: string | number = 0;
    let left: string | number = 0;
    const defaultZoom = 3;
    let lngDiff = -75;
    let latDiff = 15;
    if (lat && lng && refLng && refLat && zoom) {
      for (let i = defaultZoom; i < zoom; i++) {
        lngDiff /= 2;
        latDiff /= 1.9;
      }
      if (refLng - lng < lngDiff) {
        right = 0;
        left = 'unset';
      }
      if (refLat - lat < latDiff) {
        top = 0;
        bottom = 'unset';
      }
    }
    setCardPosStyles({ top, right, bottom, left });
  }

  return (
    <div
      className={classNames([
        styles.vesselMarkerWrapper,
        tableMode && styles.vesselMarkerWrapperTable,
      ])}>
      {vessel && (
        <div style={cardPosStyles} className={styles.vesselCardWrapper}>
          <div style={{ boxShadow: '0 5px 10px rgba(0, 0, 0, 0.7)', borderRadius: '4px' }}>
            <VesselCard element={vessel} />
          </div>
        </div>
      )}
      <div
        style={{ position: 'absolute', filter: 'drop-shadow(0 5px 10px rgba(0, 0, 0, 0.7)' }}
        onClick={onClick}>
        <PinIcon vesselType={props.type} state={state as string} />
      </div>
    </div>
  );
}

export default VesselMarker;
