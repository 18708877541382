import DemoAccessController from './controllers/accessController';
import DemoAlertController from './controllers/alertController';
import DemoCamerasController from './controllers/camerasController';
import DemoDeviceNotesController from './controllers/devices/advancedDevices/deviceNotesController';
import DemoDevicesController from './controllers/devices/advancedDevices/devicesController';
import DemoSimpleDevicesController from './controllers/devices/simpleDevices/simpleDevicesController';
import DemoDocumentsController from './controllers/documentsController';
import DemoGeofenceController from './controllers/geofenceController';
import DemoNotificationsController from './controllers/notificationsController';
import DemoRoutineController from './controllers/routineController';
import DemoUserController from './controllers/userController';
import DemoVesselController from './controllers/vesselController';

export const demoVesselController = new DemoVesselController();
export const demoDevicesController = new DemoDevicesController();
export const demoSimpleDevicesController = new DemoSimpleDevicesController();
export const demoAlertController = new DemoAlertController();
export const demoUserController = new DemoUserController();
export const demoRoutinesController = new DemoRoutineController();
export const demoDocumentsController = new DemoDocumentsController();
export const demoAccessController = new DemoAccessController();
export const demoGeofenceController = new DemoGeofenceController();
export const demoCamerasController = new DemoCamerasController();
export const demoNotificationsController = new DemoNotificationsController();
export const demoDeviceNotesController = new DemoDeviceNotesController();
