import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VesselSimpleDevicesSettings } from 'types/permissions';
import { SimpleDeviceTemplate } from 'types/simpleDevices';

import {
  getAllSimpleDevicesFlat,
  getAllSimpleDeviceTemplates,
  getSettingsData,
} from '../../api/simpleDevice';
import DelayLoading from '../delayLoading';
import { fetchFailed, fetchStart, storeLoader } from '../helpers';
import { AppThunk, MyThunkDispatch } from '../index';
import { ModuleState } from '../types';
import { SimpleDevice, SimpleDeviceLocationResponse } from './types';

export const simpleDeviceLoader = (
  dispatch: MyThunkDispatch,
  reducer: () => Promise<void>
): void => {
  storeLoader(dispatch, reducer, fetchSimpleDevicesStart, fetchSimpleDevicesFailure);
};

const delayLoading = new DelayLoading();

interface SimpleDeviceState extends ModuleState {
  devices: {
    lighting: SimpleDeviceLocationResponse[];
    shades: SimpleDeviceLocationResponse[];
    climate: SimpleDevice[];
    power: SimpleDevice[];
    maritime: SimpleDevice[];
    camera: SimpleDevice[];
    link: SimpleDevice[];
    tank: SimpleDevice[];
    mechanical: SimpleDevice[];
  };
  alerts: SimpleDevice[];
  settings: VesselSimpleDevicesSettings | null;
  notifications: null;
  flatDevices: SimpleDevice[];
  templates: SimpleDeviceTemplate[];
  isLoading: boolean;
  error: null;
  settingsLoading: boolean;
  settingsRequested: boolean;
  settingsLoadingForcing: boolean;
}

const simpleDeviceInitialState: SimpleDeviceState = {
  devices: {
    lighting: [],
    shades: [],
    climate: [],
    power: [],
    maritime: [],
    camera: [],
    link: [],
    tank: [],
    mechanical: [],
  },
  flatDevices: [],
  templates: [],
  isLoading: false,
  error: null,
  settings: null,
  notifications: null,
  alerts: [],
  settingsLoading: false,
  settingsRequested: false,
  settingsLoadingForcing: false,
};

export const fetchSimpleDeviceTemplates = (): AppThunk => async dispatch => {
  simpleDeviceLoader(dispatch, async () => {
    const response = await getAllSimpleDeviceTemplates();
    dispatch(fetchSimpleDeviceTemplatesSuccess(response.data));
  });
};

export const fetchSimpleDevicesFlat =
  (vesselId: string): AppThunk =>
  async dispatch => {
    simpleDeviceLoader(dispatch, async () => {
      const response = await getAllSimpleDevicesFlat(vesselId);
      dispatch(fetchSimpleDevicesFlatSuccess(response.data));
    });
  };

export const fetchSettingsData = delayLoading.delayLoadingAsyncThunk(
  'simpleDevices/fetchSettingsData',
  async (authCode: string | null) => {
    const response = await getSettingsData(authCode ?? undefined);
    return response?.data ?? null;
  }
);

const simpleDevices = createSlice({
  name: 'simpleDevices',
  initialState: simpleDeviceInitialState,
  reducers: {
    fetchSimpleDevicesStart: fetchStart,
    fetchSimpleDevicesFailure: fetchFailed,
    fetchSimpleDevicesSuccess(state, { payload }: PayloadAction<any>) {
      if (payload) {
        state.devices = payload;
      }
      state.isLoading = false;
      state.error = null;
    },
    fetchSettingsSuccess(state, { payload }: PayloadAction<VesselSimpleDevicesSettings>) {
      if (payload) {
        state.settings = payload;
      }
      state.isLoading = false;
      state.error = null;
    },
    fetchSimpleDeviceTemplatesSuccess(state, { payload }: PayloadAction<any>) {
      if (payload) {
        state.templates = payload;
      }
      state.isLoading = false;
      state.error = null;
    },
    fetchSimpleDevicesFlatSuccess(state, { payload }: PayloadAction<any>) {
      if (payload) {
        state.flatDevices = payload;
      }
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSettingsData.pending, state => {
      delayLoading.clearLoadingTimeout();
      state.settingsRequested = true;
    });
    builder.addCase(fetchSettingsData.typePrefix + '_LOADING_START', state => {
      state.settingsLoadingForcing = true;
      if (state.settingsRequested) {
        state.settingsLoading = true;
      }
    });
    builder.addCase(fetchSettingsData.typePrefix + '_FORCE_LOADING', state => {
      delayLoading.clearForceLoadingTimeout();
      if (!state.settingsRequested) {
        state.settingsLoading = false;
        state.settingsLoadingForcing = false;
      }
    });
    builder.addCase(fetchSettingsData.fulfilled, (state, { payload }: PayloadAction<any>) => {
      delayLoading.clearLoadingTimeout();
      state.settingsRequested = false;
      if (payload) {
        state.settings = payload;
      }
      if (!state.settingsLoadingForcing) {
        delayLoading.clearForceLoadingTimeout();
        state.settingsLoading = false;
      }
    });
  },
});

export default simpleDevices.reducer;

export const {
  fetchSimpleDevicesStart,
  fetchSimpleDevicesFailure,
  fetchSimpleDeviceTemplatesSuccess,
  fetchSimpleDevicesFlatSuccess,
  fetchSettingsSuccess,
} = simpleDevices.actions;
