import { GetVesselAuditPaginatedInterface } from 'api/selectedVessel';
import { VesselLog } from 'store/selectedVessel/types';
import { Note, Vessel } from 'store/types';
import { BasicVessel } from 'store/vessels/types';

import { createNewItemWithCustomId, storageUtil } from '../utility';
import { LogsController } from './alertController';
import DemoNotesController from './notesController';

type VesselLocation = {
  latitude: string | null;
  longitude: string | null;
  heading: number;
  speed: number;
  depth: number;
  sourceCount: number;
  sourceUsed: number;
  geofenceState: string;
  locationValid: boolean;
};

class DemoVesselController {
  private _vessel: Vessel | null = null;
  private _vesselLocation: VesselLocation | null = null;
  private _vessels: BasicVessel[] = [];
  private _notes: Note[] = [];
  private _logs: VesselLog[] = [];

  private notesController = new DemoNotesController();

  constructor() {
    this.reset();
  }

  get vessel(): Vessel | null {
    if (this._vessel?.vesselSubscription) {
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 31);
      this._vessel = {
        ...this._vessel,
        vesselSubscription: {
          ...this._vessel?.vesselSubscription,
          nextBillingDate: nextWeek.toISOString(),
        },
      };
    }

    return this._vessel;
  }

  get vesselLocation(): VesselLocation {
    this._vesselLocation = {
      latitude: this._vessel?.latitude ?? null,
      longitude: this._vessel?.longitude ?? null,
      heading: 0,
      speed: 0,
      depth: 0,
      sourceCount: 0,
      sourceUsed: 0,
      geofenceState: 'NORMAL',
      locationValid: true,
    };
    return this._vesselLocation as VesselLocation;
  }

  get vessels(): BasicVessel[] {
    this.updateVesselsList();
    return this._vessels;
  }

  get notes(): Note[] {
    this._notes = this.notesController.notes;
    return this._notes;
  }

  get logs(): VesselLog[] {
    return this._logs;
  }

  set logs(logs: VesselLog[]) {
    this._logs = logs;
  }

  reset = (): void => {
    const storageVessel = storageUtil('demo-vessel', 'GET');
    if (storageVessel) {
      this._vessel = JSON.parse(storageVessel);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      this._vessel = require('../data/VesselFull.json');
    }
    const storageVesselLogs = storageUtil('demo-vessel-logs', 'GET');
    if (storageVesselLogs) {
      this._logs = JSON.parse(storageVesselLogs);
    } else {
      this._logs = [
        {
          id: 1,
          objectId: '',
          objectName: 'System',
          messageType: 'SYSTEM',
          message: 'Demo data loaded',
          timestamp: new Date().toISOString(),
          location: '188.30.79.128',
        },
      ];
    }

    this.updateVesselsList();
  };

  updateDiscovering = (discovering: boolean): void => {
    if (this._vessel) {
      this._vessel = { ...this._vessel, discovering: discovering };
      setTimeout(() => {
        if (this._vessel) {
          this._vessel = { ...this._vessel, discovering: !discovering };
        }
      }, 5000);
    }
  };

  updateVesselsList = (): void => {
    if (this._vessel) {
      const vesselsData: BasicVessel[] = [
        {
          id: this._vessel.id,
          name: this._vessel.name,
          model: this._vessel.model,
          latitude: this._vessel.latitude,
          longitude: this._vessel.longitude,
          numberOfMonitoredDevices: this._vessel.numberOfMonitoredDevices,
          devicesOnline: this._vessel.devicesOnline,
          warningDeviceCount: this._vessel.warningCount ?? 0,
          criticalDeviceCount: this._vessel.criticalCount,
          state: this._vessel.state,
          authorised: true,
          requested: false,
          slotNumber: '',
          lastSeen: this._vessel.lastSeen,
          subscriptionState: this._vessel.vesselSubscription?.currentSubscriptionState ?? 'VOUCHER',
          userRole: 'Owner',
          manufacturer: '',
          geofenceBreached: true,
        },
        {
          id: 'FAKE-1',
          name: 'Gale',
          model: null,
          latitude: '40.4432567',
          longitude: '5.3432654',
          numberOfMonitoredDevices: 5,
          devicesOnline: 5,
          warningDeviceCount: 4,
          criticalDeviceCount: 0,
          state: 'WARNING',
          authorised: false,
          requested: false,
          slotNumber: '',
          lastSeen: '',
          subscriptionState: 'VOUCHER',
          userRole: 'Owner',
          manufacturer: '',
          geofenceBreached: true,
        },
        {
          id: 'FAKE-2',
          name: 'Pura Vida',
          model: null,
          latitude: '40.4432567',
          longitude: '29.3432654',
          numberOfMonitoredDevices: 25,
          devicesOnline: 24,
          warningDeviceCount: 2,
          criticalDeviceCount: 10,
          state: 'CRITICAL',
          authorised: false,
          requested: false,
          slotNumber: '',
          lastSeen: '',
          subscriptionState: 'VOUCHER',
          userRole: 'Owner',
          manufacturer: '',
          geofenceBreached: true,
        },
        {
          id: 'FAKE-2',
          name: 'Cleopatra',
          model: null,
          latitude: '40.4432567',
          longitude: '-10.482389',
          numberOfMonitoredDevices: 32,
          devicesOnline: 30,
          warningDeviceCount: 0,
          criticalDeviceCount: 0,
          state: 'NORMAL',
          authorised: false,
          requested: false,
          slotNumber: '',
          lastSeen: '',
          subscriptionState: 'VOUCHER',
          userRole: 'Owner',
          manufacturer: '',
          geofenceBreached: true,
        },
      ];
      this._vessels = vesselsData;
    }
  };

  updateToggle = (toggle: keyof Vessel, state: boolean): void => {
    if (this._vessel) {
      if (toggle in this._vessel) {
        this._vessel = { ...this._vessel, [toggle]: state };
        storageUtil('demo-vessel', 'POST', JSON.stringify(this._vessel));
      }
    }
  };

  updateSubscriptionToggle = (toggle: string, state: boolean): void => {
    if (this._vessel && this._vessel.currentUserPerms) {
      this._vessel = {
        ...this._vessel,
        currentUserPerms: { ...this._vessel.currentUserPerms, [toggle]: state },
      };
      storageUtil('demo-vessel', 'POST', JSON.stringify(this._vessel));
    }
  };

  updateVessel = (data: Partial<Vessel>): void => {
    if (this._vessel) {
      const newVessel = { ...this._vessel };
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (key in newVessel) {
          //Sometimes the date adds an extra 'Z' to the end causing a crash.
          const isDate = key === 'handoverDate' || key === 'buildDate' || key === 'trialDate';
          if (isDate) {
            let value = data[key as keyof Vessel] as string;
            const lastTwo = value.slice(-2);
            if (lastTwo === 'ZZ') {
              value = value.slice(0, -1);
              // @ts-ignore
              newVessel[key as keyof Vessel] = value;
              return;
            }
          }
          // @ts-ignore
          newVessel[key as keyof Vessel] = data[key as keyof Vessel];
        }
      });
      this._vessel = newVessel;
      storageUtil('demo-vessel', 'POST', JSON.stringify(this._vessel));
    }
  };

  addNewNote = (note: Note): void => {
    this.notesController.addNewNote(note);
  };
  deleteNote = (id: number): void => {
    this.notesController.deleteNote(id);
  };

  retrievePage = (data: GetVesselAuditPaginatedInterface) => {
    // this._time = this.calcDifference(data.dateFrom, data.dateTo);
    const logItems = this.logs;
    const logController = new LogsController();
    const page = logController.retrievePage(logItems, data, 'timestamp') as VesselLog[];
    return logController.mergeAlertLogData(page, data);
  };

  createNewLog = (message: string, type?: string): void => {
    const newLog = {
      id: 1,
      objectId: '',
      objectName: 'System',
      messageType: type ?? 'SYSTEM',
      message: message,
      timestamp: new Date().toISOString(),
      location: '188.30.79.128',
    };
    this._logs = createNewItemWithCustomId(this._logs, newLog, 'id');
    storageUtil('demo-vessel-logs', 'POST', JSON.stringify(this._logs));
  };
}

export default DemoVesselController;
