import { Note } from '../../store/types';
import { createNewItemWithId, storageUtil } from '../utility';

class DemoNotesController {
  private _notes: Note[] = [];

  get notes(): Note[] {
    const storageVessel = storageUtil('demo-vessel-notes', 'GET');
    if (storageVessel) {
      this._notes = JSON.parse(storageVessel);
    } else {
      this._notes = [];
    }
    return this._notes;
  }

  addNewNote = (note: Note): void => {
    const newDate = this.parseDateString(note.timestamp);
    let newNote = note;
    if (newDate) {
      newNote = { ...note, timestamp: newDate.toISOString() };
    }
    this._notes = createNewItemWithId(this._notes, newNote, 'id');
    storageUtil('demo-vessel-notes', 'POST', JSON.stringify(this._notes));
  };

  deleteNote = (id: number): void => {
    this._notes = this._notes.filter(note => note.id !== id);
    storageUtil('demo-vessel-notes', 'POST', JSON.stringify(this._notes));
  };

  parseDateString(dateString: string): Date | null {
    const parts = dateString.split(/[\/,:\s]/);
    if (parts.length === 7) {
      const day = parseInt(parts[0], 10);
      const month = parseInt((Number(parts[1]) - 1).toString(), 10); // Months are zero-based in JavaScript
      const year = parseInt(parts[2], 10);
      const hours = parseInt(parts[4], 10);
      const minutes = parseInt(parts[5], 10);
      const seconds = parseInt(parts[6], 10);
      return new Date(year, month, day, hours, minutes, seconds);
    } else {
      return null;
    }
  }
}

export default DemoNotesController;
