import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { brandLightGreyText } from '../../styles/colors';
import { MiscIcon } from '../DynamicSVGs/misc';
import DeleteButton from '../iconButtons/DeleteButton';
import styles from './Input2.module.scss';

type Props = {
  error?: boolean;
  placeholder?: string;
  prefilled?: boolean;
  short?: boolean;
  width?: string;
  data?: string;
  disabled?: boolean;
  containerStyle?: any;
  secureTextEntry?: any;
  fakePlaceHolder?: boolean;
  disableWithFill?: boolean;
  // The 'clear' and 'allowClear' are for Alerts Levels input fields
  clear?: () => void;
  allowClear?: string;
} & React.ComponentProps<'input'>;

const Input = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    placeholder,
    prefilled,
    short,
    width,
    data,
    error,
    disabled,
    containerStyle,
    secureTextEntry,
    clear,
    allowClear,
    ...inputProps
  } = props;
  const [inputState, setInputState] = useState(false);
  const [inputPrefilled, setInputPrefilled] = useState(prefilled);
  const [, setValue] = useState(data);
  const [hidePassword, setHidePassword] = useState(secureTextEntry);
  useEffect(() => {
    setValue(data);
  }, [data]);

  let length: string;
  short ? (length = '180px') : width ? (length = width) : (length = '360px');

  return (
    <div className={styles.Input} style={{ width: length, ...(containerStyle && containerStyle) }}>
      <div
        style={props.disableWithFill || props.fakePlaceHolder ? { color: brandLightGreyText } : {}}
        className={
          !disabled
            ? inputState
              ? styles.labelActive
              : inputPrefilled || data
              ? styles.labelInactiveBlank
              : styles.labelInactive
            : styles.labelDisabled
        }>
        {placeholder}
      </div>
      <input
        style={
          props.fakePlaceHolder || props.disableWithFill
            ? {
                color: brandLightGreyText,
                borderColor: brandLightGreyText,
              }
            : {}
        }
        className={
          inputState
            ? error
              ? styles.inputActiveError
              : styles.inputActive
            : inputPrefilled || data
            ? styles.inputInactiveBlank
            : styles.inputInactive
        }
        onFocus={() => setInputState(true)}
        onBlur={e => {
          if (!e.target.value.length) {
            setInputState(false);
            setInputPrefilled(false);
          }
        }}
        disabled={disabled}
        type={hidePassword ? 'password' : 'text'}
        {...inputProps}
        ref={ref}
      />
      {secureTextEntry && (inputState || data) ? (
        <div
          style={{
            flex: 1,
            position: 'absolute',
            right: 5,
            top: 18,
          }}
          onClick={() => {
            setHidePassword(!hidePassword);
          }}>
          <MiscIcon
            type={hidePassword ? 'eye' : 'EYE_CLOSED'}
            status={'BLACK'}
            iconStyle={{ width: 25 }}
          />
        </div>
      ) : null}
      {/*The 'clear' and 'allowClear' are for Alerts Levels input fields*/}
      {clear && allowClear !== 'NOT SET' ? (
        <ClearButton onClick={clear}>
          <DeleteButton />
        </ClearButton>
      ) : null}
    </div>
  );
});

export default Input;

const ClearButton = styled.div`
  position: absolute;
  right: 8px;
  top: 19px;
  cursor: pointer;
`;
