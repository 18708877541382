import React, { ReactElement, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import arrow from '../../assets/images/arrow.svg';
import ContentLoader from '../ContentLoader';
import SearchInput from '../SearchInput';
import TableFooter from './TableFooter';
import { HeaderRowItem } from './Types';
import usePaginate from './usePaginate';

export type ExpandedRowInterface = {
  id: number | string;
  element: React.ReactNode;
};

type Props = {
  data: any | null;
  headerRow: HeaderRowItem[];
  dataType?: string;
  handleRowClick?: (row: any) => void;
  loading?: boolean;
  showSearch?: boolean;
  paginate?: boolean;
  toExpand?: any;
  tableHeader?: React.ReactNode;
  expandedRows?: ExpandedRowInterface[];
  allowScroll?: boolean;
  info?: React.ReactNode;
  searchCallback?: (searchValue: string) => any;
  headerMargin?: boolean;
  // SearchCallback functionality relies on ID, please use only if data-set contains unique IDs for each object
};

const defaultProps = {
  showSearch: true,
  paginate: true,
  expandedRow: null,
  data: [],
  allowScroll: true,
};
//80

const NewTable = (props: Props): ReactElement => {
  const [pageSize, setPageSize] = useState(props.paginate ? 10 : -1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentPage, pages, updateCurrentPage, toDisplay] = usePaginate(filteredData, pageSize);
  const [sortController, setSortController] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const arrowDown = <Down src={arrow} alt="" />;
  const arrowUp = <Up src={arrow} alt="" />;

  const headerDisplay = props.headerRow.map(item => {
    const theme = {
      cursor: item.sortBy ? 'pointer' : 'default',
    };
    if (!item.hidden) {
      return (
        <th key={uuidv4()}>
          <ThemeProvider theme={theme}>
            <HeaderText
              onClick={() => {
                if (!item.sortBy) {
                  return;
                }
                setSortController(prev => {
                  if (item.sortBy && sortBy !== item.sortBy) {
                    setSortBy(item.sortBy);
                    return 1;
                  }
                  return prev === 2 ? 0 : prev + 1;
                });
              }}>
              {item.text}
            </HeaderText>
          </ThemeProvider>
          {sortController === 1 && item.sortBy === sortBy
            ? arrowUp
            : sortController === 2 && item.sortBy === sortBy && arrowDown}
        </th>
      );
    }
  });

  useEffect(() => {
    updateCurrentPage(1);
  }, [pageSize]);

  const resolvePath = (object: any, path: string): any =>
    path.split('.').reduce((o, p) => (o && o[p] !== null ? o[p] : '-'), object);

  function naturalCompare(a: string | number, b: string | number): number {
    const aValue = typeof a === 'number' ? a.toString() : a;
    const bValue = typeof b === 'number' ? b.toString() : b;

    const regex = /(\d+)/g;

    const aMatches = aValue.toLowerCase().match(regex) || [];
    const bMatches = bValue.toLowerCase().match(regex) || [];

    const aParts = aValue.toLowerCase().split(regex);
    const bParts = bValue.toLowerCase().split(regex);

    let aIndex = 0;
    let bIndex = 0;

    while (aIndex < aParts.length && bIndex < bParts.length) {
      const aPart = aParts[aIndex];
      const bPart = bParts[bIndex];

      const aNumber = aMatches.shift();
      const bNumber = bMatches.shift();

      const aIsDigit = /^\d+$/.test(aPart);
      const bIsDigit = /^\d+$/.test(bPart);

      if (aIsDigit !== bIsDigit) {
        if (aIndex === 0 && bIndex === 0) {
          return aIsDigit ? 1 : -1;
        }
        return aIndex - bIndex;
      }
      if (aPart !== bPart) {
        return aPart.localeCompare(bPart);
      }
      if (aNumber && bNumber) {
        const numDiff = parseInt(aNumber, 10) - parseInt(bNumber, 10);
        if (numDiff !== 0) {
          return numDiff;
        }
      }
      aIndex++;
      bIndex++;
    }
    return aParts.length - bParts.length;
  }

  useEffect(() => {
    const filtered = props.data.filter((dataItem: any) => {
      let match = false;
      props.headerRow.forEach(item => {
        if (item.accessor) {
          const searchRes = resolvePath(dataItem, item.accessor);
          if (typeof searchRes === 'string') {
            const matchFound: boolean = searchRes.toLowerCase().includes(searchValue.toLowerCase());
            if (matchFound) {
              match = true;
            }
            return !matchFound;
          }
        }
      });
      return match;
    });
    let combinedResult = filtered;
    if (props.data.some((item: any) => item.id !== undefined)) {
      const uniqueIds = new Set(filtered.map((item: { id: any }) => item.id));
      if (props.searchCallback && searchValue) {
        const res = props.searchCallback(searchValue);
        res.forEach((item: { id: any }) => uniqueIds.add(item.id));
      }
      combinedResult = props.data.filter((item: { id: any }) => uniqueIds.has(item.id));
    }
    const sorted = [...combinedResult].sort((a: any, b: any) => {
      let itemA = resolvePath(a, sortBy);
      let itemB = resolvePath(b, sortBy);
      if (itemA === itemB) {
        return 0;
      }
      if (itemA === null || itemB === null) {
        return itemA === null ? 1 : -1;
      }
      itemA = typeof itemA === 'string' && !isNaN(Number(itemA)) ? parseFloat(itemA) : itemA;
      itemB = typeof itemB === 'string' && !isNaN(Number(itemB)) ? parseFloat(itemB) : itemB;

      const itemAIsNumber = typeof itemA === 'number';
      const itemBIsNumber = typeof itemB === 'number';
      const itemAIsBoolean = typeof itemA === 'boolean';
      const itemBIsBoolean = typeof itemB === 'boolean';

      if (itemAIsNumber && itemBIsNumber) {
        return sortController === 1 ? itemA - itemB : itemB - itemA;
      } else if (itemAIsNumber) {
        return 1;
      } else if (itemBIsNumber) {
        return -1;
      } else if (itemAIsBoolean && itemBIsBoolean) {
        return sortController === 1 ? Number(itemA) - Number(itemB) : Number(itemB) - Number(itemA);
      } else if (itemAIsBoolean) {
        return 1;
      } else if (itemBIsBoolean) {
        return -1;
      } else {
        if (itemA && itemB) {
          return sortController === 1
            ? naturalCompare(itemB.toString(), itemA.toString())
            : naturalCompare(itemA.toString(), itemB.toString());
        } else if (itemA) {
          return 1;
        } else if (itemB) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    setFilteredData(sortController > 0 ? sorted : combinedResult);
  }, [searchValue, sortController, sortBy, props.data, props.headerRow]);

  const tableTheme = {
    columns: props.headerRow.map(item => {
      if (!item.hidden) {
        return item.columnSizing
          ? `minmax(${item.columnSizing.default.min}, ${item.columnSizing.default.max} )`
          : 'minmax(min-content, auto)';
      }
    }),
    smallColumns: props.headerRow.map(item => {
      if (!item.hidden) {
        return item.columnSizing && item.columnSizing.small
          ? `minmax(${item.columnSizing.small.min}, ${item.columnSizing.small.max} )`
          : item.columnSizing
          ? `minmax(${item.columnSizing.default.min}, ${item.columnSizing.default.max} )`
          : 'minmax(min-content, auto)';
      }
    }),
    largeColumns: props.headerRow.map(item => {
      if (!item.hidden) {
        return item.columnSizing && item.columnSizing.large
          ? `minmax(${item.columnSizing.large.min}, ${item.columnSizing.large.max} )`
          : item.columnSizing
          ? `minmax(${item.columnSizing.default.min}, ${item.columnSizing.default.max} )`
          : 'minmax(min-content, auto)';
      }
    }),
    scroll: props.allowScroll ? 'scroll' : 'initial',
    toolbarMarginBottom: props.info ? '0px' : '10px',
    toolbarMarginTop: props.headerMargin ? '-10px' : '0px',
  };

  const data = toDisplay.map((data: any, index) => {
    const rowBackground = index % 2 !== 0 ? '#fafafa' : 'white';
    const element = props.expandedRows?.find(item => {
      return item.id === data.id;
    });
    const elementExists: boolean = element !== undefined;
    return (
      <ThemeProvider
        key={uuidv4()}
        theme={{ hover: props.handleRowClick && !elementExists && '#D3D3D3', rowBackground }}>
        <StyledRow
          style={{ cursor: props.handleRowClick && !elementExists ? 'pointer' : 'initial' }}>
          {props.headerRow.map(item => {
            if (!item.hidden) {
              return (
                <td
                  onClick={e => {
                    if (item.ignoreClick || element !== undefined) {
                      return;
                    }
                    props.handleRowClick && props.handleRowClick(data);
                  }}
                  key={uuidv4()}>
                  {item.cb
                    ? item.cb(data, item.accessor && resolvePath(data, item.accessor))
                    : item.accessor && resolvePath(data, item.accessor)}
                </td>
              );
            }
          })}
          <ExpandedRow>{element && element.element}</ExpandedRow>
        </StyledRow>
      </ThemeProvider>
    );
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer>
        {props.showSearch && (
          <TableToolbar>
            {props.tableHeader ? props.tableHeader : <div />}
            {''}
            <SearchBar>
              {props.showSearch && (
                <SearchInput
                  placeholder="Search"
                  value={searchValue || ''}
                  onChange={e => {
                    setSearchValue(e.target.value);
                  }}
                  autoComplete={'off'}
                />
              )}
            </SearchBar>
          </TableToolbar>
        )}
        {props.info && props.info}
        <Table>
          <thead>
            <HeaderRow>{headerDisplay}</HeaderRow>
          </thead>
          {props.loading ? (
            <LoaderWrap>
              <ContentLoader fillMode={true} />{' '}
            </LoaderWrap>
          ) : (
            <tbody>{data}</tbody>
          )}
        </Table>
        {props.paginate && (
          <TableFooter
            loading={props.loading}
            currentPage={currentPage}
            pages={pages}
            updateCurrentPage={updateCurrentPage}
            setPageSize={setPageSize}
            dataType={props.dataType}
            dataLength={filteredData.length}
            pageLength={toDisplay.length}
            pageSize={pageSize}
          />
        )}
      </TableContainer>
    </ThemeProvider>
  );
};
export default NewTable;
NewTable.defaultProps = defaultProps;

const Table = styled.table`
  display: grid;
  border-collapse: collapse;
  grid-template-columns: ${props => props.theme.columns};
  overflow: ${props => (props.theme.scroll === 'scroll' ? 'auto' : props.theme.scroll)};
  border: solid 1px #e9e9e9;
  border-radius: 4px;
  width: 100%;
  justify-self: center;
  margin-bottom: 1.5em;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
  thead,
  tbody,
  tr {
    display: contents;
  }
  tr {
    cursor: ${props => props.theme.cursor};
  }
  th,
  td {
    padding: 10px;
  }
  th {
    background: #e9e9e9;
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    color: #5e5f64;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    justify-items: start;
    height: 40px;
    align-content: center;
    align-items: center;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 2;
  }
  th:last-child {
    border: 0;
  }
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #5e5f64;
    display: grid;
    align-items: center;
    background-color: white;
    align-content: center;
    min-width: min-content;
    width: 100%;
  }
  tr td {
    min-height: 60px;
    height: 100%;
  }

  tr:nth-child(even) td {
    background-color: #fafafa;
  }

  @media screen and (max-width: 1640px) {
    grid-template-columns: ${props => props.theme.largeColumns};
  }

  @media screen and (max-width: 1240px) {
    grid-template-columns: ${props => props.theme.smallColumns};
  }
`;

const StyledRow = styled.tr`
  &:hover {
    td {
      background-color: ${props => props.theme.hover} !important;
    }
  }
`;

const HeaderRow = styled.tr`
  text-align: left;
`;

const TableContainer = styled.div`
  display: grid;
`;

const TableToolbar = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  margin-bottom: ${props => props.theme.toolbarMarginBottom};
  margin-top: ${props => props.theme.toolbarMarginTop};
  grid-template-columns: 1fr auto;
  width: 100%;
  justify-content: end;
  height: 60px;
`;

const SearchBar = styled.div`
  // changed spacing requirements to 10px, so we don't need here margin-left (extra filters should have margin-right: 10px)
  //margin-left: 10px;
  & select {
    &:focus {
      border-color: rgba(0, 0, 0, 0.25);
      outline: 0;
    }
  }
`;

const Down = styled.img`
  width: 12px;
  margin-left: 10px;
`;

const Up = styled.img`
  width: 12px;
  margin-left: 10px;
  transform: rotate(180deg);
`;

const HeaderText = styled.p`
  :hover {
    cursor: ${props => props.theme.cursor};
  }
  font-size: 16px;
`;

const ExpandedRow = styled.div`
  width: 100%;
  grid-column: 1/-1;
  display: grid;
  background-color: ${props => props.theme.rowBackground};
`;

const LoaderWrap = styled.div`
  justify-self: center;
  display: grid;
  grid-column: 1/-1;
  min-height: 50vh;
`;
