import { ValueHolder } from '../../../../store/selectedDevice/types';
import { NewSimpleDevice } from '../../../../store/simpleDevice/types';
import { VesselSimpleDevicesSettings } from '../../../../types/permissions';
import {
  SimpleDevice,
  SimpleDeviceFieldWrapper,
  SimpleDeviceLocationResponse,
  TankGroup,
} from '../../../../types/simpleDevices';
import {
  demoAccessController,
  demoAlertController,
  demoCamerasController,
  demoDevicesController,
  demoVesselController,
} from '../../../provider';
import { StorageKey } from '../../../types';
import {
  generateLinkBridge,
  getAllSimpleDevicesStorage,
  getParsedValue,
  storageUtil,
  systems,
} from '../../../utility';
import { PropertyAlertConfig } from '../../alertController';
import { generateSimpleDevices, retrieveLocations } from './simpleDevicesUtils';

type DeviceType = { devices: SimpleDevice[] };
type LocationType = { locations: SimpleDeviceLocationResponse[] };
type GaugeType = { items: SimpleDevice[] | TankGroup[] };

class DemoSimpleDevicesController {
  private _settings: VesselSimpleDevicesSettings = {
    unitPreference: 'METRIC',
    timeFormat24Hr: true,
    lightingStatus: 'DISABLED',
    climateStatus: 'DISABLED',
    powerStatus: 'DISABLED',
    maritimeStatus: 'DISABLED',
    shadesStatus: 'DISABLED',
    camerasStatus: 'DISABLED',
    geofenceStatus: 'DISABLED',
    linkStatus: 'DISABLED',
    tankStatus: 'DISABLED',
    mechanicalStatus: 'DISABLED',
    alerts: false,
    alertLevel: 'NORMAL',
    warningCount: 0,
    criticalCount: 0,
    lightCount: 0,
    shadesCount: 0,
    soundWarning: false,
    soundCritical: false,
    showWarning: false,
    showCritical: false,
    ackWarning: false,
    ackCritical: false,
    ackWarningCount: 0,
    ackCriticalCount: 0,
    soundWarningCount: 0,
    soundCriticalCount: 0,
    localTime: '',
    utcTime: '',
    engineDashboard: 'DISABLED',
    tankDashboard: 'DISABLED',
  };
  private _devices: DeviceType | LocationType | null = null;
  private _type = '';
  private _gauges: GaugeType = { items: [] };
  private _locations: string[] = [];

  constructor() {
    this.reset();
  }

  get settings(): VesselSimpleDevicesSettings {
    if (!this._settings) {
      this.reset();
    }

    const defaultInterface = demoAccessController.interfaces.find(
      interfaceItem => interfaceItem.id === 'guest'
    );

    if (!defaultInterface) {
      return this._settings as VesselSimpleDevicesSettings;
    }
    defaultInterface.categories.forEach(category => {
      const name = category.name;
      let newName = name;
      if (name === 'Marine') {
        newName = 'maritime';
        const status = this.checkStatusBySystem('MARITIME', category.allowed, true);
        this._settings = {
          ...(this._settings && this._settings),
          engineDashboard: status,
        };
      } else if (name === 'Tanks') {
        newName = 'Tank';
        const status = this.checkStatusBySystem('TANK', category.allowed, true);
        this._settings = {
          ...(this._settings && this._settings),
          tankDashboard: status,
        };
      }
      const status = this.checkStatusBySystem(newName.toUpperCase(), category.allowed);
      // @ts-ignore
      this._settings = {
        ...(this._settings ?? {}),
        [(newName.toLowerCase() + 'Status') as keyof VesselSimpleDevicesSettings]: status,
      };
    });
    return this._settings as VesselSimpleDevicesSettings;
  }

  get devices(): DeviceType | LocationType | null {
    return this._devices;
  }

  get gauges(): GaugeType {
    return this._gauges;
  }

  get locations(): string[] {
    this._locations = retrieveLocations();
    return this._locations;
  }

  reset = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    this._settings = require('../../../data/simpleDevices/Settings.json');
    const alertLevel = this.getVesselAlertLevel();
    this._settings = { ...this._settings, alertLevel };
  };

  checkStatusBySystem = (system: string, allowed: string, gauge?: boolean): string => {
    const systemDevices = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
    let status = 'DISABLED';
    if (allowed !== 'NONE') {
      status = 'NORMAL';
      if (systemDevices) {
        if (gauge) {
          const include = this.includeInGauges(JSON.parse(systemDevices));
          status = include ? 'NORMAL' : 'DISABLED';
          if (status !== 'DISABLED') {
            status = this.checkStatus(JSON.parse(systemDevices as string));
          }
        } else {
          status = this.checkStatus(JSON.parse(systemDevices as string));
        }
      }
    }

    return status;
  };

  includeInGauges = (devices: SimpleDevice[]): boolean => {
    return devices.some(device => device.fields.some(field => field.includeInGauges));
  };

  checkStatus = (devices: SimpleDevice[]): string => {
    let status = 'NORMAL';
    devices.forEach(device => {
      if (status === 'CRITICAL') {
        return status;
      }
      device.fields.forEach(field => {
        if (status === 'CRITICAL') {
          return status;
        }
        const alertConfig: Partial<PropertyAlertConfig> | undefined =
          demoAlertController.propertyAlertConfigs.find(
            propertyAlertConfig => propertyAlertConfig.propertyId === field.dpvhId
          );
        if (alertConfig) {
          if (alertConfig.state === 'CRITICAL') {
            status = 'CRITICAL';
            return 'CRITICAL';
          } else if (alertConfig.state === 'WARNING') {
            status = 'WARNING';
          }
        }
      });
    });
    return status;
  };

  getVesselAlertLevel = (): string => {
    let alertLevel = 'NORMAL';
    demoDevicesController.devices.forEach(device => {
      if (alertLevel === 'CRITICAL') {
        return;
      }
      const deviceAlertLevel = device.state;
      if (deviceAlertLevel === 'WARNING' && alertLevel === 'NORMAL') {
        alertLevel = 'WARNING';
      }
      if (
        deviceAlertLevel === 'CRITICAL' &&
        (alertLevel === 'NORMAL' || alertLevel === 'WARNING')
      ) {
        alertLevel = 'CRITICAL';
      }
    });
    return alertLevel;
  };

  retrieveAllDevicesFlat = (): SimpleDevice[] => {
    return getAllSimpleDevicesStorage();
  };

  retrieveDevice = (type: string): DeviceType | LocationType => {
    if (type === 'LINK') {
      const linkBridgeDevice = demoDevicesController.retrieveDeviceInfo('1');
      if (linkBridgeDevice) {
        const newDevices = [generateLinkBridge(linkBridgeDevice)];
        this._devices = {
          devices: newDevices,
        };
        this._type = type;
        return this._devices as DeviceType | LocationType;
      }
    }
    if (type === 'CAMERA') {
      const cameras = demoCamerasController.cameras;
      this._devices = {
        devices: [
          ...cameras.map(camera => {
            return {
              location: camera.location,
              name: camera.name,
              id: camera.id,
              warningState: 'NORMAL',
              manufacturer: 'null',
              model: 'null',
              serial: 'null',
              system: 'CAMERA',
              subSystem: 'CCTV',
              controllable: false,
              online: true,
              displayComponent: 'camera',
              fields: [],
              selectable: false,
              state: 'NORMAL',
              templateName: 'Camera',
              metaData: { capacity: '' },
              friendlyName: camera.name,
            } as SimpleDevice;
          }),
        ],
      };
      this._type = type;
      return this._devices as DeviceType | LocationType;
    }
    const systemDevices = storageUtil(`demo-vessel-simpledevice-${type}` as StorageKey, 'GET');
    if (systemDevices) {
      const newDevices = JSON.parse(systemDevices);
      const locations: SimpleDeviceLocationResponse[] = [];
      const returnDevices: SimpleDevice[] = [];
      let advDeviceId: string | null = null;
      newDevices.forEach((device: SimpleDevice) => {
        let locationActive = false;
        const fields: SimpleDeviceFieldWrapper[] = [];
        let deviceStatus = 'NORMAL';
        device.fields.forEach(field => {
          let valueHolderFound: ValueHolder | null = null;
          if ((!field.dpvhId && !field.metaField) || (field.metaField && !field.value)) {
            return field;
          }
          demoDevicesController.devices.forEach(advDevice => {
            if (valueHolderFound) {
              return;
            }
            advDeviceId = advDevice.id;
            valueHolderFound =
              advDevice.valueHolders.find(valueHolder => valueHolder.id === field.dpvhId) ?? null;
          });
          if (field.metaField) {
            fields.push({ ...field, deviceOnline: true, warningState: 'NORMAL' });
            return;
          }
          if (!valueHolderFound) {
            return field;
          }
          demoDevicesController.randomUpdateValueHolder(
            advDeviceId as string,
            (valueHolderFound as ValueHolder).id
          );
          valueHolderFound = valueHolderFound as ValueHolder;
          if (
            valueHolderFound.value !== '0' &&
            (type === 'LIGHTING'
              ? field.fieldName === 'state' || field.fieldName === 'currentPosition'
              : field.fieldName === 'currentPosition')
          ) {
            locationActive = true;
          }
          const alertConfig: Partial<PropertyAlertConfig> | undefined =
            demoAlertController.propertyAlertConfigs.find(
              propertyAlertConfig =>
                propertyAlertConfig.propertyId === (valueHolderFound as ValueHolder).id
            );
          if (alertConfig) {
            if (alertConfig.state === 'CRITICAL' && deviceStatus !== 'CRITICAL') {
              deviceStatus = 'CRITICAL';
            } else if (alertConfig.state === 'WARNING' && deviceStatus === 'NORMAL') {
              deviceStatus = 'WARNING';
            }
          }
          fields.push({
            ...field,
            controllable: valueHolderFound.controllable,
            loggable: valueHolderFound.loggingEnabled,
            displayName: field.displayName,
            warnLevelLow: alertConfig?.warnLevelLow ?? 'NOT SET',
            warnLevelHigh: alertConfig?.warnLevelHigh ?? 'NOT SET',
            criticalLevelLow: alertConfig?.criticalLevelLow ?? 'NOT SET',
            criticalLevelHigh: alertConfig?.criticalLevelHigh ?? 'NOT SET',
            value: valueHolderFound.value,
            parsedValue: getParsedValue(valueHolderFound),
            dpvhName: valueHolderFound.propertyName,
            dictionary: valueHolderFound.dictionary as Record<number, string>[],
            name: valueHolderFound.name,
            warningState: alertConfig?.state ?? 'NORMAL',
            deviceOnline: true,
            lastSeen: new Date().toISOString(),
            validData: 'true',
            unit: valueHolderFound.unit,
            gaugeLow: valueHolderFound.gaugeLow,
            gaugeHigh: valueHolderFound.gaugeHigh,
          });
        });
        const newDevice = {
          ...device,
          fields: fields,
          warningState: deviceStatus,
        };
        let containsField = false;
        newDevice.fields.forEach(field => {
          if (field.dpvhId || (field.metaField && field.value)) {
            containsField = true;
          }
        });
        if (containsField) {
          returnDevices.push(newDevice as SimpleDevice);
          if (type === 'LIGHTING' || type === 'SHADES') {
            const locationIndexFound = locations.findIndex(
              location => location.name === newDevice.location
            );
            if (locationIndexFound !== -1) {
              locations[locationIndexFound] = {
                ...locations[locationIndexFound],
                devices: [...locations[locationIndexFound].devices, newDevice as SimpleDevice],
                activeCount: locations[locationIndexFound].activeCount + (locationActive ? 1 : 0),
                statusValue:
                  locations[locationIndexFound].activeCount + (locationActive ? 1 : 0)
                    ? type === 'SHADES'
                      ? 'CLOSED'
                      : 'ON'
                    : type === 'SHADES'
                    ? 'OPEN'
                    : 'OFF',
              };
            } else {
              locations.push({
                activeCount: locationActive ? 1 : 0,
                statusValue: locationActive
                  ? type === 'SHADES'
                    ? 'CLOSED'
                    : 'ON'
                  : type === 'SHADES'
                  ? 'OPEN'
                  : 'OFF',
                name: newDevice.location,
                devices: [newDevice as SimpleDevice],
              });
            }
          }

          return newDevice;
        }
      });
      if (type === 'LIGHTING' || type === 'SHADES') {
        this._devices = {
          locations: locations,
        } as LocationType;
      } else {
        this._devices = {
          devices: returnDevices,
        };
      }
    }
    this._type = type;
    return this._devices as DeviceType | LocationType;
  };

  retrieveGauges = (type: string): GaugeType => {
    if (this._gauges.items.length > 0 && this._type === type) {
      return this._gauges;
    }
    this._type = type;
    let gaugeDevices: DeviceType = { devices: [] };
    switch (type) {
      case 'ENGINE':
        this._devices = null;
        gaugeDevices = this.retrieveDevice('MARITIME') as DeviceType;
        this._gauges = {
          items: this.retrieveGaugeDashboard('ENGINE', gaugeDevices.devices).devices,
        };
        return this._gauges;
      case 'TANK':
        this._devices = null;
        gaugeDevices = this.retrieveDevice('TANK') as DeviceType;
        this._gauges = {
          items: this.retrieveGaugeDashboard('TANK', gaugeDevices.devices).devices,
        };
        return this._gauges;
    }
    return { items: [] };
  };

  retrieveGaugeDashboard = (
    type: string,
    devices: SimpleDevice[]
  ): { devices: SimpleDevice[] | TankGroup[] } => {
    if (type === 'TANK') {
      const tanks: TankGroup[] = [];
      devices.forEach(device => {
        let includeInGauges = false;
        let system = 'Ungrouped';
        let volume = 0;
        let capacity = 0;
        device.fields.forEach(field => {
          if (field.fieldName === 'fluidLevel') {
            includeInGauges = field.includeInGauges;
          }
          if (field.fieldName === 'System') {
            system = field.value;
          }
          if (field.fieldName === 'Capacity') {
            capacity = Number(field.value);
          }
          if (field.fieldName === 'Volume') {
            volume = Number(field.value);
          }
        });
        if (!includeInGauges) {
          return;
        }
        const foundTankSystemIndex = tanks.findIndex(tank => tank.name === system);
        if (foundTankSystemIndex !== -1 && tanks.length > 0) {
          const newItems = [...tanks[foundTankSystemIndex].items, device];
          const totalVolume = tanks[foundTankSystemIndex].totalVolume + volume;
          const totalCapacity = tanks[foundTankSystemIndex].totalCapacity + capacity;
          tanks[foundTankSystemIndex] = {
            ...tanks[foundTankSystemIndex],
            items: newItems,
            totalVolume: totalVolume,
            totalCapacity: totalCapacity,
            percentage: Math.round((totalVolume / totalCapacity) * 100),
          };
        } else {
          tanks.push({
            totalVolume: volume,
            totalCapacity: capacity,
            tankCount: 5,
            items: [device],
            warningState: 'NORMAL',
            name: system,
            capacityUnit: 'L',
            volumeUnit: 'L',
            percentage: (volume / capacity) * 100,
          });
        }
      });
      return { devices: tanks };
    } else {
      const engineDevices = devices.filter(device => device.subSystem === 'ENGINE');
      const filteredEngines: SimpleDevice[] = [];
      engineDevices.forEach(device => {
        const gaugeFields: SimpleDeviceFieldWrapper[] = [];
        device.fields.forEach(field => {
          if (field.includeInGauges) {
            gaugeFields.push(field);
          }
        });
        filteredEngines.push({ ...device, fields: gaugeFields });
      });
      return { devices: filteredEngines };
    }
  };

  setIncludeInGauges = (fieldId: number, include: boolean) => {
    const [fieldFound, systemFound, devicesFound] = this.updateField(
      { includeInGauges: include },
      field => field.id === fieldId
    );
    if (fieldFound && devicesFound.length > 0) {
      storageUtil(
        `demo-vessel-simpledevice-${systemFound}` as StorageKey,
        'POST',
        JSON.stringify(devicesFound)
      );
    }
    this._gauges = { items: [] };
  };

  updateField = (
    newField: Partial<SimpleDeviceFieldWrapper>,
    fieldConditional: (field: SimpleDeviceFieldWrapper) => boolean,
    deviceConditional?: (device: SimpleDevice) => boolean
  ): [SimpleDeviceFieldWrapper | null, string, SimpleDevice[]] => {
    let fieldFound: SimpleDeviceFieldWrapper | null = null;
    let systemFound = '';
    let devicesFound: SimpleDevice[] = [];
    systems.forEach(system => {
      //Break out of array if the device system has been found
      if (fieldFound || devicesFound.length > 0) {
        return;
      }
      const systemDevice = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
      if (systemDevice) {
        const devices = JSON.parse(systemDevice);
        if (devices.length === 0) {
          return;
        }
        (devices as SimpleDevice[]).forEach((device, deviceIndex) => {
          if (
            devicesFound.length > 0 ||
            (deviceConditional !== undefined && deviceConditional(device))
          ) {
            return;
          }
          const fieldIndex = device.fields.findIndex(field => fieldConditional(field));
          if (fieldIndex === -1) {
            return;
          }
          systemFound = system;
          fieldFound = {
            ...device.fields[fieldIndex],
            ...newField,
          };
          devicesFound = devices;
          devicesFound[deviceIndex].fields[fieldIndex] = fieldFound;
        });
      }
    });
    return [fieldFound, systemFound, devicesFound];
  };

  updateSimpleDeviceData = (
    id: number,
    name: string,
    manufacturer: string,
    model: string,
    serial: string,
    location: string
  ): void => {
    let systemFound = '';
    let devicesFound: SimpleDevice[] = [];
    systems.forEach(system => {
      if (systemFound || devicesFound.length > 0) {
        return;
      }
      const systemDevice = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
      if (systemDevice) {
        const devices = JSON.parse(systemDevice);
        if (devices.length === 0) {
          return;
        }
        (devices as SimpleDevice[]).forEach((device, deviceIndex) => {
          if (devicesFound.length > 0) {
            return;
          }
          if (device.id === id) {
            systemFound = system;
            devicesFound = devices;
            devicesFound[deviceIndex] = {
              ...devices[deviceIndex],
              name,
              manufacturer,
              model,
              serial,
              location,
            };
          }
        });
      }
    });
    if (devicesFound.length > 0) {
      storageUtil(
        `demo-vessel-simpledevice-${systemFound}` as StorageKey,
        'POST',
        JSON.stringify(devicesFound)
      );
    }
  };

  sendCommand = (deviceProperty: number, value: number | string): void => {
    demoDevicesController.updateDevicePropertyValue(deviceProperty, value);
    if (!this._devices) {
      return;
    }
    if (this._type === 'LIGHTING' || this._type === 'SHADES') {
      (this._devices as { locations: SimpleDeviceLocationResponse[] }).locations.forEach(
        (location: any) => {
          location.devices.forEach((device: SimpleDevice) => {
            device.fields.forEach(field => {
              if (field.dpvhId === deviceProperty) {
                field.value = value as string;
                if (Object.keys(field.dictionary).length > 0) {
                  const newVal = value === 100 || value === '100' ? 1 : 0;
                  field.parsedValue = field.dictionary[Number(newVal)].toString();
                } else {
                  field.parsedValue = value + field.unit ? field.unit : '';
                }
              }
            });
          });
        }
      );
    } else {
      (this._devices as DeviceType).devices.forEach((device: SimpleDevice) => {
        device.fields.forEach(field => {
          if (field.dpvhId === deviceProperty) {
            field.value = value as string;
            if (Object.keys(field.dictionary).length > 0) {
              if (isNaN(Number(value))) {
                field.parsedValue = value.toString();
              } else {
                field.parsedValue = field.dictionary[Number(value)].toString();
              }
            } else {
              field.parsedValue = value as string;
            }
          }
        });
      });
    }
  };

  updateLocation = (location: string, state: string): void => {
    if (!this._devices) {
      return;
    }
    const locationFound = (
      this._devices as { locations: SimpleDeviceLocationResponse[] }
    ).locations.find(locationItem => locationItem.name === location);
    if (!locationFound) {
      return;
    }
    locationFound.devices.forEach(device => {
      device.fields.forEach(field => {
        demoDevicesController.updateDevicePropertyValue(
          field.dpvhId,
          state === 'off' || state === 'open' ? '0' : field.fieldName === 'state' ? '1' : '100'
        );
      });
    });
  };

  createSimpleDevice = (device: NewSimpleDevice): void => {
    generateSimpleDevices(device.name, device.templateId, device.location);
    demoVesselController.createNewLog('Simple Device Created', 'USER');
  };

  setMetadata = (id: number, key: string, value: string): void => {
    const [fieldFound, systemFound, devicesFound] = this.updateField(
      { value: value, parsedValue: value, dpvhName: value },
      field => field.fieldName === key,
      device => device.id !== id
    );
    if (fieldFound && devicesFound.length > 0) {
      storageUtil(
        `demo-vessel-simpledevice-${systemFound}` as StorageKey,
        'POST',
        JSON.stringify(devicesFound)
      );
    }
  };

  deleteSimpleDevice = (id: number): void => {
    let deviceFound: SimpleDevice | null = null;
    systems.forEach(system => {
      if (deviceFound) {
        return;
      }
      const systemDevices = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
      if (!systemDevices) {
        return;
      }
      let devices = JSON.parse(systemDevices) as SimpleDevice[];
      deviceFound = devices.find(device => device.id === id) ?? null;
      if (deviceFound) {
        devices = devices.filter(device => device.id !== id);
        storageUtil(
          `demo-vessel-simpledevice-${system}` as StorageKey,
          'POST',
          JSON.stringify(devices)
        );
      }
    });
  };
}

export default DemoSimpleDevicesController;
