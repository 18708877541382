import { ValueHolder, VesselDevice } from 'store/selectedDevice/types';
import { SimpleDevice, SimpleDeviceFieldWrapper } from 'types/simpleDevices';

import { InitialDeviceI } from '../../../dataImporter';
import { getMetaDataValue } from '../../../utility';

export class DemoSimpleDevicesMapImporter {
  private _devices: VesselDevice[] = [];
  private _initialDevice: InitialDeviceI;
  private _newDevice: SimpleDevice;
  private _deviceFound: VesselDevice;
  private _splitValues: [string, string, number][] = [];
  constructor(
    advDevices: VesselDevice[],
    initialDevice: InitialDeviceI,
    newDevice: SimpleDevice,
    deviceFound: VesselDevice
  ) {
    this._splitValues = [];
    this._devices = advDevices;
    this._initialDevice = initialDevice;
    this._newDevice = newDevice;
    this._deviceFound = deviceFound;
  }

  mapToSimpleDevice = (): SimpleDeviceFieldWrapper[] => {
    return this.mapCategoryProperties();
  };

  normalizeString = (str: string) => {
    // Convert to lowercase and remove spaces
    return str.toLowerCase().replace(/\s/g, '');
  };

  findFieldsAndVhs = (
    fieldTypes: string[],
    vhNames: string[]
  ): [(SimpleDeviceFieldWrapper | null)[], (ValueHolder | string | null)[]] => {
    const fields: (SimpleDeviceFieldWrapper | null)[] = [];
    fieldTypes.forEach((fieldType, fieldIndex) => {
      //Multiple of the same type could exist so narrow down to one field.
      const fieldsFound = this._newDevice.fields.filter(field => field.fieldType === fieldType);
      let fieldFound = fieldsFound && fieldsFound.length > 0 ? fieldsFound[0] : undefined;
      if (fieldsFound.length > 1) {
        fieldFound = fieldsFound.find(
          field =>
            this.normalizeString(field.fieldName) === this.normalizeString(vhNames[fieldIndex])
        );
      }
      fields.push(fieldFound ?? null);
    });
    const valueHolders: (ValueHolder | string | null)[] = [];
    vhNames.forEach((vhName, vhIndex) => {
      const split = getMetaDataValue(vhName);
      if (split[0] !== '') {
        this._splitValues.push([...split, vhIndex]);
        valueHolders.push(vhName ?? null);
        return;
      }
      const vhFound = this._deviceFound.valueHolders.find(vh => vh.name === vhName);
      valueHolders.push(vhFound ?? null);
    });
    return [fields, valueHolders];
  };

  mapProperties = (
    fields: (SimpleDeviceFieldWrapper | null)[],
    valueHolders: (ValueHolder | string | null)[]
  ): SimpleDeviceFieldWrapper[] => {
    const newFields = [];
    for (let i = 0; i < fields.length; i++) {
      let skip = false;

      if (fields[i] === null || (valueHolders[i] === null && this._splitValues[0][0] === '')) {
        skip = true;
      }
      if (this._splitValues.length > 0) {
        const splitValueToSend = this._splitValues.find(split => split[2] === i);
        if (splitValueToSend) {
          newFields.push({
            ...fields[i],
            value: splitValueToSend[1],
            parsedValue: splitValueToSend[1],
            dpvhName: splitValueToSend[1],
          } as SimpleDeviceFieldWrapper);
          skip = true;
        }
      }
      if (!valueHolders[i]) {
        skip = true;
      }
      if (!skip) {
        const newVh = valueHolders[i] as ValueHolder;
        const newField = {
          ...fields[i],
          displayName: this._initialDevice.name ? newVh.name : '',
          dpvhId: newVh.id,
          dpvhName: newVh.name,
        } as SimpleDeviceFieldWrapper;
        newFields.push(newField);
      }
    }
    return newFields;
  };

  mapCategoryProperties = (): SimpleDeviceFieldWrapper[] => {
    if (!this._initialDevice.fields && !this._initialDevice.valueHolders) {
      return [];
    }
    const [fields, vhs] = this.findFieldsAndVhs(
      this._initialDevice.fields,
      this._initialDevice.valueHolders
    );
    return this.mapProperties(fields, vhs);
  };
}
