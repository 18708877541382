import { postAcceptInvite } from 'api/vessels';
// @ts-ignore
import classNames from 'classnames';
import AlertModal from 'components/AlertModal';
import Button from 'components/Button';
import FlexContainer from 'components/FlexContainer';
import { demoVesselImgCheck } from 'demo/utility';
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { changeVesselDashboardTab } from 'store/selectedVessel';
import { fetchAllVessels, fetchPendingVesselInvitations } from 'store/vessels';
import { BasicVessel } from 'store/vessels/types';
import styled from 'styled-components';
import { brandBlue, brandDarkGrey, brandRed } from 'styles/colors';
import ErrorBoundary from 'utils/ErrorBoundary';

import styles from './VesselCard.module.scss';

type Props = {
  element: BasicVessel;
  invitations?: boolean;
};

function VesselCard(props: Props): ReactElement {
  const {
    name,
    latitude,
    longitude,
    model,
    numberOfMonitoredDevices,
    criticalDeviceCount,
    warningDeviceCount,
    devicesOnline,
    id,
    state,
    authorised,
    geofenceBreached,
    subscriptionState,
  } = props.element;
  const navigate = useNavigate();
  const invitations = props.invitations;
  const { corporatePerms, userData } = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();
  const apiLink = process.env.REACT_APP_API_TARGET;
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');

  function handleAlert(success: boolean, msg?: string): void {
    setMsg(msg ?? '');
    setAlertType(success);
    setShowAlert(true);
  }

  function handleVesselCardClick(): void {
    if (userData?.corporate && !corporatePerms?.technicianUser) {
      // navigate(`/dashboard/request-access/${id}/false/${userData.companyName}`);
    } else {
      if (authorised) {
        navigate(`/dashboard/${id}/overview`);
      } else {
        // navigate(`/dashboard/request-access/${id}/${requested}`);
      }
    }
    dispatch(changeVesselDashboardTab('OVERVIEW'));
  }

  function handleInviteAcceptation(state: boolean): void {
    postAcceptInvite(id, state).then(() => {
      if (state) {
        handleAlert(true, 'Vessel Invitation Accepted');
        dispatch(fetchPendingVesselInvitations());
        dispatch(fetchAllVessels());
      } else {
        navigate('/dashboard/map');
      }
    });
  }

  const subscriptionFalse =
    subscriptionState === 'PAYMENTFAIL' ||
    subscriptionState === 'CANCELLED' ||
    subscriptionState === 'SUSPENDED' ||
    subscriptionState === 'INVALID';

  return (
    <div
      onClick={() => (invitations ? null : handleVesselCardClick())}
      className={
        !invitations
          ? classNames([
              styles.cardElement,
              state === 'NORMAL' && styles.cardElementNormal,
              state === 'WARNING' && styles.cardElementWarning,
              state === 'CRITICAL' && styles.cardElementCritical,
              state === 'OFFLINE' && styles.cardElementOffline,
              // !authorised && styles.cardElementUnauthorised,
            ])
          : classNames([styles.cardElement, styles.cardElementNormal])
      }
      style={{ cursor: authorised ? 'pointer' : 'unset' }}>
      <Main>
        <div>
          <FlexContainer className={styles.leftSide}>
            <img src={demoVesselImgCheck(name, `${apiLink}/utility/vesselImage/${id}`)} alt="" />
            <FlexContainer
              justifyContent="space-between"
              flexFlow="column"
              style={{ marginRight: '10px' }}>
              <VesselName>
                {name}
                <br />
                {!authorised && <AccessPaused>(access paused or disabled)</AccessPaused>}
                {model && (
                  <FriendlyName>
                    {model.manufacturer.name} {model.name}
                  </FriendlyName>
                )}
              </VesselName>

              {!subscriptionFalse && latitude && longitude ? (
                <Row>
                  {geofenceBreached ? (
                    <span style={{ color: brandRed }}>
                      Location: {latitude}, {longitude}
                    </span>
                  ) : (
                    <span>
                      Location: {latitude}, {longitude}
                    </span>
                  )}
                </Row>
              ) : (
                <>
                  {subscriptionFalse ? (
                    <Row>Location: No Subscription</Row>
                  ) : (
                    <Row>Location: Unknown</Row>
                  )}
                </>
              )}
              <Devices>
                <span className={styles.vesselNormal}>{devicesOnline} Devices online</span>
                <span>{numberOfMonitoredDevices} Devices monitored</span>
                {!!criticalDeviceCount && (
                  <span className={styles.vesselCritical}>
                    {criticalDeviceCount} Devices critical
                  </span>
                )}
                {!!warningDeviceCount && (
                  <span className={styles.vesselWarning}>{warningDeviceCount} Devices warning</span>
                )}
              </Devices>
            </FlexContainer>
          </FlexContainer>
        </div>

        {invitations && (
          <ButtonsWrapper>
            <Button
              size="normal"
              variant="info"
              onClick={() => {
                handleInviteAcceptation(true);
              }}>
              Accept Invitation
            </Button>
            <Button
              variant="custom"
              onClick={() => {
                handleInviteAcceptation(false);
              }}>
              Decline Invitation
            </Button>
          </ButtonsWrapper>
        )}
      </Main>
      {showAlert && (
        <ErrorBoundary modal={{ name: 'Alert', close: setShowAlert }}>
          <AlertModal success={alertType} msg={msg} handleClose={() => setShowAlert(false)} />
        </ErrorBoundary>
      )}
    </div>
  );
}

export default VesselCard;

const Main = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100px;
  button {
    margin: 0 !important;
    width: 150px;
  }
`;

const AccessPaused = styled.span`
  color: ${brandDarkGrey};
`;

const VesselName = styled.div`
  color: ${brandBlue};
  font-size: 18px;
`;

const FriendlyName = styled.div`
  font-size: 16px;
`;

const Row = styled.div`
  margin: 5px 0;
`;

const Devices = styled.div`
  display: flex;
  flex-flow: column;
  font-size: 16px;
`;
