import { VesselDevice } from 'store/selectedDevice/types';
import { SimpleDevice } from 'types/simpleDevices';

import { DemoDeviceGenerator } from './controllers/devices/advancedDevices/deviceGenerator';
import { DemoSimpleDevicesMapImporter } from './controllers/devices/simpleDevices/simpleDeviceDataImporter';
import { generateSimpleDevices } from './controllers/devices/simpleDevices/simpleDevicesUtils';
import { StorageKey } from './types';
import { getAllSimpleDevicesStorage, storageUtil } from './utility';

export interface InitialDeviceI {
  name: string;
  type: string;
  deviceName: string;
  location?: string;
  fields: string[];
  valueHolders: string[];
}

export interface InitialValueHolderI {
  name: string;
  uid: string;
  value?: string;
  status?: {
    value: string;
    warnLow?: string;
    warnHigh?: string;
    critLow?: string;
    critHigh?: string;
  };
  gaugeLow?: number;
  gaugeHigh?: number;
  instance?: string;
}

export const defaultAlertConfig = {
  instant: false,
  warnLevelHigh: 'NOT SET',
  warnLevelLow: 'NOT SET',
  criticalLevelHigh: 'NOT SET',
  criticalLevelLow: 'NOT SET',
  state: 'NORMAL',
  lastTriggeredValue: '',
  ackTimestamp: null,
  ackUserId: null,
  ackUserName: '',
  acknowledged: false,
  alertDelayEnable: false,
  alertDelayMs: 0,
  alertMessage: '',
  repeatOptions: 'NEVER',
  silenceTimestamp: null,
  silenceUserId: null,
  silenceUsername: '',
  silenced: false,
  instantAlertLevel: 'NORMAL',
};

const simpleDeviceName = (initialDevice: InitialDeviceI, deviceFoundName: string): string => {
  const name = deviceFoundName;
  if (initialDevice.deviceName === 'Lighting' || initialDevice.deviceName === 'Climate') {
    return initialDevice.name;
  }
  return name;
};

export const generateAdvancedDevices = (): VesselDevice[] => {
  const devices: {
    name: string;
    bus: string;
    valueHolders: InitialValueHolderI[];
    // eslint-disable-next-line @typescript-eslint/no-var-requires
  }[] = require('demo/data/devices/AdvancedDevices.json');
  const newDevices: VesselDevice[] = [];
  let deviceId = 1;
  let valueHolderId = 1;
  devices.forEach(device => {
    const deviceGenerator = new DemoDeviceGenerator(
      device.name,
      device.bus,
      device.valueHolders,
      deviceId,
      valueHolderId
    );
    const newDevice = deviceGenerator.device;
    newDevices.push(newDevice);
    deviceId++;
    valueHolderId = valueHolderId + newDevice.valueHolders.length;
  });
  if (newDevices.length > 0) {
    storageUtil('demo-vessel-advanced-devices', 'POST', JSON.stringify(newDevices));
  }
  return newDevices;
};

export const generateSimpleDevicesFromData = (advDevices: VesselDevice[]): void => {
  const devices = getAllSimpleDevicesStorage(true);
  const newDevices: SimpleDevice[] = [];
  if (devices.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const initialDevices = require('demo/data/simpleDevices/initialDevices.json');
    initialDevices.forEach((initialDevice: InitialDeviceI, index: number) => {
      const deviceFound = advDevices.find(device => device.name === initialDevice.deviceName);
      if (deviceFound) {
        let newDevice = generateSimpleDevices(
          simpleDeviceName(initialDevice, deviceFound.name),
          initialDevice.type,
          initialDevice.location ?? 'No Location'
        );
        const simpleDeviceMap = new DemoSimpleDevicesMapImporter(
          advDevices,
          initialDevice,
          newDevice,
          deviceFound
        );
        const newMappedDeviceFields = simpleDeviceMap.mapToSimpleDevice();
        newDevice = {
          ...newDevice,
          id: index,
          fields: newMappedDeviceFields,
          name: initialDevice.name,
        };
        newDevices.push(newDevice);
      }
    });
    const newDevicesSplit = splitArrayBySystem(newDevices);
    newDevicesSplit.forEach((devices, system) => {
      if (devices.length > 0) {
        storageUtil(
          `demo-vessel-simpledevice-${system}` as StorageKey,
          'POST',
          JSON.stringify(devices)
        );
      }
    });
  }
};

const splitArrayBySystem = (devices: SimpleDevice[]): Map<string, SimpleDevice[]> => {
  const result = new Map<string, SimpleDevice[]>();
  devices.forEach(item => {
    const system = item.system;
    let prev: SimpleDevice[] = [];
    if (result.has(system)) {
      prev = result.get(system) ?? [];
    }
    result.set(system, [...prev, item]);
  });
  return result;
};

export const generateValueHoldersAlertConfigs = (advDevices: VesselDevice[]): void => {
  const prevAlertConfigs = storageUtil('demo-vessel-valueholders-alert-configs', 'GET');
  if (advDevices.length === 0 || (prevAlertConfigs && JSON.parse(prevAlertConfigs).length > 0)) {
    return;
  }
  const alertConfigs = [];
  const tankAlert = createDeviceAlertConfig(
    '1',
    1,
    'CRITICAL',
    'NOT SET',
    'NOT SET',
    'NOT SET',
    '10'
  );
  if (tankAlert) {
    alertConfigs.push(tankAlert);
  }
  if (alertConfigs.length > 0) {
    storageUtil('demo-vessel-valueholders-alert-configs', 'POST', JSON.stringify(alertConfigs));
  }
};

export const generateValueHoldersAlertConfig = (
  advDeviceId: number,
  valueHolderId: number,
  status: string,
  statusData: {
    value: string;
    warnLow?: string;
    warnHigh?: string;
    critLow?: string;
    critHigh?: string;
  }
): void => {
  const prevAlertConfigs = storageUtil('demo-vessel-valueholders-alert-configs', 'GET');

  let alertConfigs = [];
  if (prevAlertConfigs) {
    alertConfigs = JSON.parse(prevAlertConfigs);
  }
  const alertConfig = createDeviceAlertConfig(
    advDeviceId.toString(),
    valueHolderId,
    status,
    statusData.warnLow ?? 'NOT SET',
    statusData.warnHigh ?? 'NOT SET',
    statusData.critLow ?? 'NOT SET',
    statusData.critHigh ?? 'NOT SET'
  );
  if (alertConfig) {
    alertConfigs.push(alertConfig);
  }
  if (alertConfigs.length > 0) {
    storageUtil('demo-vessel-valueholders-alert-configs', 'POST', JSON.stringify(alertConfigs));
  }
};

const createDeviceAlertConfig = (
  deviceId: string,
  propertyId: number,
  state?: string,
  warnLow?: string,
  warnHigh?: string,
  critLow?: string,
  critHigh?: string
) => {
  return {
    ...defaultAlertConfig,
    deviceId: deviceId,
    propertyId: propertyId,
    warnLevelHigh: warnHigh ?? 'NOT SET',
    warnLevelLow: warnLow ?? 'NOT SET',
    criticalLevelHigh: critHigh ?? 'NOT SET',
    criticalLevelLow: critLow ?? 'NOT SET',
    state: state ?? 'NORMAL',
  };
};
