import demoVesselImg from 'assets/images/my-demo-yacht.jpg';
import { SearchResponse } from 'views/DashboardRouter/components/GlobalSearch/types';

import { ValueHolder, VesselDevice } from '../store/selectedDevice/types';
import { SimpleDevice } from '../types/simpleDevices';
import { demoDevicesController, demoUserController, demoVesselController } from './provider';
import { StorageAction, StorageKey } from './types';

export const systems = [
  'LIGHTING',
  'CLIMATE',
  'SHADES',
  'POWER',
  'MARITIME',
  'TANK',
  'MECHANICAL',
  'LINK',
];

export const storageUtil = (
  key: StorageKey,
  action: StorageAction,
  value?: string
): string | null | void => {
  switch (action) {
    case 'GET':
      return localStorage.getItem(key);
    case 'POST':
      if (value) {
        localStorage.setItem(key, value);
      }
      break;
    case 'DELETE':
      localStorage.removeItem(key);
      break;
  }
};

export const clearDemoData = (): void => {
  storageUtil('demo-vessel-users', 'DELETE');
  storageUtil('demo-vessel-documents', 'DELETE');
  storageUtil('demo-vessel-notes', 'DELETE');
  storageUtil('demo-vessel-geofence', 'DELETE');
  storageUtil('demo-vessel-advanced-devices', 'DELETE');
  storageUtil('demo-vessel-valueholders-alert-configs', 'DELETE');
  storageUtil('demo-vessel-alerts', 'DELETE');
  storageUtil('demo-vessel-alert-logs', 'DELETE');
  storageUtil('demo-vessel-cameras', 'DELETE');
  storageUtil('demo-vessel-routines', 'DELETE');
  storageUtil('demo-vessel-appsettings', 'DELETE');
  storageUtil('demo-vessel', 'DELETE');
  storageUtil('demo-vessel-logs', 'DELETE');
  storageUtil('demo-vessel-calibrations', 'DELETE');
  storageUtil('demo-vessel-custom-alerts', 'DELETE');
  storageUtil('demo-vessel-notifications', 'DELETE');
  storageUtil('demo-vessel-device-notes', 'DELETE');
  demoVesselController.logs = [];
  removeAllSimpleDevicesStorage();
};

export const getAllSimpleDevicesStorage = (stopOnDevice?: boolean): SimpleDevice[] => {
  const devices: SimpleDevice[] = [];
  systems.forEach(system => {
    if (stopOnDevice && devices.length > 0) {
      return;
    }
    const systemDevice = storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'GET');
    if (systemDevice) {
      devices.push(...JSON.parse(systemDevice));
    }
  });
  return devices;
};

export const removeAllSimpleDevicesStorage = (): void => {
  systems.forEach(system => {
    storageUtil(`demo-vessel-simpledevice-${system}` as StorageKey, 'DELETE');
  });
  storageUtil('demo-vessel-simpledevice-locations', 'DELETE');
};

export const getRandom = (min: number, max: number, decimalPlaces: number): number => {
  const rand = Math.random() * (max - min) + min;
  const power = Math.pow(10, decimalPlaces);
  return Math.floor(rand * power) / power;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const createNewItemWithId = <Type>(
  dataArr: Type[],
  data: Type,
  idKey: keyof Type
): Type[] => {
  if (dataArr.length > 0) {
    const lastItem = dataArr.length - 1;
    const lastId: number = Number(dataArr[lastItem][idKey]) ?? 0;
    let itemWithNewId = { ...data, id: lastId + 1 };
    if (!isNaN(Number(dataArr[lastItem][idKey]))) {
      itemWithNewId = {
        ...itemWithNewId,
        userId: Number(dataArr[lastItem][idKey]) + 1,
      };
    } else {
      itemWithNewId = { ...itemWithNewId, userId: 1 };
    }
    return [...dataArr, itemWithNewId] as Type[];
  } else {
    return [...dataArr, { ...data, id: 0 }] as Type[];
  }
};

export const createNewItemWithCustomId = <Type>(
  dataArr: Type[],
  data: Type,
  idKey: keyof Type,
  stringId?: boolean
): Type[] => {
  if (dataArr.length > 0) {
    const lastItem = dataArr.length - 1;
    const lastId: number = Number(dataArr[lastItem][idKey]) ?? 0;
    let itemWithNewId = { ...data, id: lastId + 1 };
    if (!isNaN(Number(dataArr[lastItem][idKey]))) {
      const newId = Number(dataArr[lastItem][idKey]) + 1;
      itemWithNewId = {
        ...itemWithNewId,
        [idKey]: stringId ? newId.toString() : newId,
      };
    } else {
      itemWithNewId = { ...itemWithNewId, [idKey]: stringId ? '1' : 1 };
    }
    return [...dataArr, itemWithNewId] as Type[];
  } else {
    return [...dataArr, { ...data, [idKey]: stringId ? '1' : 1 }] as Type[];
  }
};

export const generateLinkBridge = (linkBridgeDevice: VesselDevice): SimpleDevice => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const fields = require('demo/data/simpleDevices/LinkProps.json');
  return {
    id: 0,
    location: '',
    manufacturer: '',
    model: '',
    serial: '',
    state: 'NORMAL',
    templateName: 'link',
    warningState: 'NORMAL',
    name: 'LINKbridge',
    controllable: true,
    online: true,
    system: 'LINK',
    subSystem: 'LINKbridge',
    displayComponent: 'linkBridge',
    metaData: { capacity: '' },
    friendlyName: 'LINKbridge',
    fields: fields.map(
      (field: { fieldName: string; fieldType: string; propUid: string; instance: string }) => {
        const valueHolderFound = linkBridgeDevice.valueHolders.find(
          valueHolder => valueHolder.propertyUid === field.propUid
        );
        if (!valueHolderFound) {
          return {};
        }
        return {
          fieldName: field.fieldName,
          fieldType: field.fieldName,
          value: valueHolderFound.value,
          unit: valueHolderFound.unit,
          parsedValue: getParsedValue(valueHolderFound),
          dpvhId: valueHolderFound.id,
          dpvhName: valueHolderFound.name,
          displayName: valueHolderFound.userDefinedName ?? valueHolderFound.propertyName,
          criticalLevelHigh: valueHolderFound.criticalLevelHigh,
          criticalLevelLow: valueHolderFound.criticalLevelLow,
          warnLevelLow: valueHolderFound.warnLevelLow,
          warnLevelHigh: valueHolderFound.warnLevelHigh,
          loggable: valueHolderFound.loggingEnabled,
          controllable: valueHolderFound.controllable,
          dictionary: valueHolderFound.dictionary,
          gaugeLow: valueHolderFound.gaugeLow,
          gaugeHigh: valueHolderFound.gaugeHigh,
          warningState: valueHolderFound.state,
          deviceOnline: true,
        };
      }
    ),
  };
};

export const getParsedValue = (valueHolder: Partial<ValueHolder>): string => {
  let parseValue;
  if (
    valueHolder.dictionary &&
    Object.keys(valueHolder.dictionary).length > 0 &&
    valueHolder.value
  ) {
    // @ts-ignore
    parseValue = valueHolder.dictionary[valueHolder.value];
  } else {
    parseValue = (valueHolder.value ? valueHolder.value : '') + valueHolder.unit;
  }
  return parseValue;
};

export const demoVesselImgCheck = (vesselName: string, imgUrl: string): string => {
  return vesselName === 'MY Demo Yacht' ? demoVesselImg : imgUrl;
};

const filterByName = <Type>(array: Type[], searchTerm: string, key: keyof Type): Type[] => {
  return array.filter(item => {
    if (!item) {
      return false;
    }
    if (!item[key]) {
      return false;
    }
    // @ts-ignore
    return item[key].includes(searchTerm);
  });
};

export const demoSearch = (searchValue: string): SearchResponse => {
  const users = demoUserController.users;
  const vessels = demoVesselController.vessels;
  const devices = demoDevicesController.devices;
  const filteredVessels = filterByName(vessels, searchValue, 'name');
  const filteredUsers = filterByName(users, searchValue, 'userDisplayName');
  const filteredDevices = filterByName(devices, searchValue, 'name');
  return {
    vessels: filteredVessels,
    models: [],
    devices: filteredDevices,
    contacts: filteredUsers,
  };
};

export const getMetaDataValue = (name: string): [string, string] => {
  if (name.length < 10) {
    return ['', ''];
  }
  const type = name.substring(0, 8);
  const value = name.substring(9, name.length);
  if (type === 'METADATA') {
    return [type, value];
  }
  return ['', ''];
};
