import { UserPreferences } from 'store/profile/types';

import { demoUserController } from '../demo/provider';
import { MyResponse, RequestConfig, requestMiddleware } from './request';

export async function getCurrentUserDetails(): Promise<MyResponse> {
  return requestMiddleware(demoUserController.userDetails);
}
export async function getUserPreferences(): Promise<MyResponse<UserPreferences>> {
  return requestMiddleware({
    aisOverlay: false,
    timeFormat24Hr: false,
    publicProfile: false,
    smsNotify: false,
    emailNotify: false,
    whatsAppNotify: false,
    measurementUnits: 'METRIC',
    languageOption: null,
  });
}

export async function getNavigationProfileForUser(): Promise<MyResponse> {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return requestMiddleware(require('demo/data/Navigation.json'));
}

export async function getUserProfileDetails(): Promise<MyResponse> {
  return requestMiddleware(demoUserController.userFull);
}

export async function postUploadUserProfileImage(userProfileImage: FormData): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/profileImage',
    method: 'POST',
    data: userProfileImage,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function postEditProfileData(profileData: any): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/edit',
    method: 'POST',
    data: profileData,
  });
}

export async function getVendors(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/profile/vendors',
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postDeleteUser(userId: any): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/delete',
    method: 'POST',
    data: {
      userId,
    },
  });
}

export async function postConfirmDeleteUser(authCode: any): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/profile/confirmDelete?code=${authCode}`,
    method: 'POST',
  });
}

export async function postChangePassword(currentPassword: string): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/changePassword',
    method: 'POST',
    data: currentPassword,
  });
}

export async function postUpdateVendorStatus({
  vendorId,
  state,
}: {
  vendorId: string;
  state: boolean;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/profile/vendors/${vendorId}/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getVesselsForVendor(vendorId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/profile/vendors/${vendorId}/vesselList`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function pauseCompanyAccessToVessel(
  vesselId: string,
  vendorId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/pauseCompany/${vendorId}/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function addCompanyToVessel(vesselId: string, vendorId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/addCompany/${vendorId}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function revokeCompanyAccessToVessel(
  vesselId: string,
  vendorId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/revokeCompany/${vendorId}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}
