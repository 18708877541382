import { ValueHolder } from '../../../../store/selectedDevice/types';
import CAN_ATMOSPHERIC_PROPS from '../../../data/devices/properties/can-atmospheric.json';
import CAN_ELECTRICAL_PROPS from '../../../data/devices/properties/can-electrical.json';
import CAN_ENGINE_PROPS from '../../../data/devices/properties/can-engine.json';
import CAN_FLUIDS_PROPS from '../../../data/devices/properties/can-fluids.json';
import CAN_NAV_PROPS from '../../../data/devices/properties/can-nav.json';
import GUDE_PROPS from '../../../data/devices/properties/gude.json';
import KNX_PROPS from '../../../data/devices/properties/knx.json';
import LINK_DEFAULT_PROPS from '../../../data/devices/properties/link.json';
import { generateValueHoldersAlertConfig, InitialValueHolderI } from '../../../dataImporter';

type DefaultPropType = {
  name: string;
  uid: string;
  dictionary: Record<string, string>;
  instantReport: boolean;
  controllable: boolean;
  loggingByDefault: boolean;
  category: string;
  alternativeName: string;
  activeByDefault: boolean;
  includeInSummaryByDefault: boolean;
  minVal?: number;
  maxVal?: number;
  unit: string;
};

export const buses = [
  { bus: 'MODBUSTCP', source: 'Modbus TCP', supportsPropertyAdd: true },
  { bus: 'MODBUSRTU', source: 'Modbus RTU', supportsPropertyAdd: true },
  { bus: 'KNX', source: 'KNX', supportsPropertyAdd: true },
  { bus: 'GUDE', source: 'Gude Power Management', supportsPropertyAdd: false },
  { bus: 'CAN1', source: 'Can Port 1', supportsPropertyAdd: false },
  { bus: 'CAN2', source: 'Can Port 2', supportsPropertyAdd: false },
  { bus: 'SYSTEM', source: 'LINK Devices', supportsPropertyAdd: false },
  { bus: 'N0183', source: 'NMEA 0183', supportsPropertyAdd: false },
  { bus: 'NETWORK', source: 'Network Devices', supportsPropertyAdd: false },
  { bus: 'CZONE', source: 'CZone', supportsPropertyAdd: false },
  { bus: 'DOMETIC', source: 'Dometic', supportsPropertyAdd: false },
  { bus: 'NETWORK', source: 'Network Devices', supportsPropertyAdd: false },
  { bus: 'BYME', source: 'Vimar By-Me', supportsPropertyAdd: false },
];

export const generateValueHolders = (
  valueHolderId: number,
  deviceId: number,
  valueHolders: InitialValueHolderI[],
  deviceName: string,
  busName: string
): [Partial<ValueHolder>[], string] => {
  let id = valueHolderId;
  const newValueHolders: Partial<ValueHolder>[] = [];
  let status = 'NORMAL';
  valueHolders.forEach(valueHolder => {
    let property: DefaultPropType | undefined;
    if (deviceName === 'LINKbridge') {
      // @ts-ignore
      property = LINK_DEFAULT_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
    } else {
      switch (busName) {
        case 'GUDE':
          // @ts-ignore
          property = GUDE_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
        case 'KNX':
          // @ts-ignore
          property = KNX_PROPS.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
        case 'CAN2':
          const props = [
            ...CAN_ENGINE_PROPS,
            ...CAN_ELECTRICAL_PROPS,
            ...CAN_ATMOSPHERIC_PROPS,
            ...CAN_FLUIDS_PROPS,
            ...CAN_NAV_PROPS,
            ...KNX_PROPS, //Uses KNXBINARYSWITCH-1
          ];
          // @ts-ignore
          property = props.find((prop: DefaultPropType) => prop.uid === valueHolder.uid);
          break;
      }
    }
    if (valueHolder.status) {
      if (valueHolder.status.value === 'CRITICAL' && status !== 'CRITICAL') {
        status = 'CRITICAL';
      } else if (valueHolder.status.value === 'WARNING' && status === 'NORMAL') {
        status = 'WARNING';
      }
      generateValueHoldersAlertConfig(deviceId, valueHolderId, status, valueHolder.status);
    }
    newValueHolders.push({
      id: id,
      name: valueHolder.name,
      propertyUid: property ? property.uid : 'FLUID-LEVEL-1',
      propertyName: property ? property.name : 'DPT_FluidLevel (1.001)',
      fullName: `${deviceName} ${valueHolder.name}`,
      value: valueHolder.value ?? '0',
      category: property ? property.category : 'Light',
      unit: property ? property.unit : '',
      // @ts-ignore
      dictionary: property ? property.dictionary ?? {} : {},
      includeInSummary: property ? property.includeInSummaryByDefault : false,
      deviceGroup: '',
      loggingEnabled: property ? property.loggingByDefault : false,
      gaugeHigh: valueHolder.gaugeHigh ?? 0.0,
      gaugeLow: valueHolder.gaugeLow ?? 0.0,
      rangeHigh: property ? property.maxVal ?? 0.0 : 0,
      rangeLow: property ? property.minVal ?? 0.0 : 0,
      curveId: 0,
      state: valueHolder?.status?.value ?? 'NORMAL',
      hasAlertConfig: !!valueHolder.status,
      controllable: property ? property.controllable : false,
      instance: valueHolder.instance ? valueHolder.instance : '0',
    });
    id++;
  });
  return [newValueHolders, status];
};
