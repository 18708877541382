import { Note } from '../../../../store/types';
import { createNewItemWithCustomId, storageUtil } from '../../../utility';

type DeviceNote = Note & { deviceId: string };

class DemoDeviceNotesController {
  private _deviceNotes: DeviceNote[] = [];
  constructor() {
    const storageNotes = storageUtil('demo-vessel-device-notes', 'GET');
    if (storageNotes) {
      this._deviceNotes = JSON.parse(storageNotes);
    } else if (this._deviceNotes.length !== 0) {
      this._deviceNotes = [];
    }
  }

  addNote = (deviceId: string, msg: string): void => {
    const note = {
      creator: 'Demo User',
      timestamp: new Date().toISOString(),
      id: 0,
      note: msg,
      deviceId: deviceId,
    };
    this._deviceNotes = createNewItemWithCustomId(this._deviceNotes, note, 'id');
    storageUtil('demo-vessel-device-notes', 'POST', JSON.stringify(this._deviceNotes));
  };

  retrieveNotes = (deviceId: string): Note[] => {
    const notes = this._deviceNotes.filter(note => note.deviceId === deviceId);
    if (notes && notes.length > 0) {
      return notes;
    }
    return [];
  };

  deleteNote = (deviceId: string, noteId: number): void => {
    this._deviceNotes = this._deviceNotes.filter(note => note.id !== noteId);
    storageUtil('demo-vessel-device-notes', 'POST', JSON.stringify(this._deviceNotes));
  };
}

export default DemoDeviceNotesController;
